import React from 'react';

//  import page components
import Ean from './ean';
import {scan_getCssClass} from "../../../services/scan-service";

//  ---
//  Page/Component ScanView
//  Can be called from different pages: scan-preview, history-view, transfer-view
//  ---
const ScanView = (props) => {
    //  ---
    //  return
    //  ---
    return (
        <div id="scanView">
            { Object.keys(props.scanItemList).map(ean => {
                return (
                    <Ean
                        // provide unique key for this element
                        key={'ean-'+ean}

                        // settings
                        printScannedValue={props.printScannedValue}             //  display the scanned quantity?
                        printDefaults={props.printDefaults}                     //  display the default-values information?
                        printLatestDeliveryDate={props.printLatestDeliveryDate} //  display latest delivery date?
                        printActionButtons={props.printActionButtons}           //  print + - x buttons?
                        useStatusStyle={props.useStatusStyle}                   //  use stockCssClass

                        // data we need
                        eanQuantity={props.scanItemList[ean].currentQuantity}   //  to trigger quantity-updates by form input
                        ean={props.scanItemList[ean]}                           //  the full ean data object to render
                        cust={props.cust}                                       //  the current used customer
                        page={props.page}                                       //  the requested page
                        scanItemList={props.scanItemList}                       //  to trigger re-rendering
                        currentEanCssClass={scan_getCssClass(props.cust, props.scanItemList[ean], props.scanItemList[ean].currentQuantity)}

                    />
                );
            })}
        </div>
    );
}

export default ScanView;
