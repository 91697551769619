import React from 'react';
import { FiArrowLeftCircle } from 'react-icons/fi';

//  import styles
import 'bootstrap/dist/css/bootstrap.min.css';

//  import components
import ScanView from './../scan/view';
import Statistics from '../../../components/statistics';

// import services
import { date_getDateTimeString } from '../../../services/date-service';
import { scan_prepareScanItems } from '../../../services/scan-service';

//  ---
//  Page/Component HistoryView
//  ---
const HistoryView = (props) => {

    //  make data access easier
    let data = props.stateProps;

    //  get scanItemList for historic scan
    let scanItemList = scan_prepareScanItems(props.cust, props.page, props.fullDefaultValuesList, data.scanId);

    //  ---
    //  return
    //  ---
    return (
        <>
            <h1>{"Historie - ScanID "+data.scanId}</h1>
            <div className={'card dotted plain'}>
                <h2>Info</h2>
                <table className={'innerGrid'}>
                    <tbody>
                    <tr>
                        <td style={{ 'width': '150px'}}>Scan-Datum</td>
                        <td>{ date_getDateTimeString(data.scanData.info.scanDate, true) }</td>
                    </tr>
                    <tr>
                        <td style={{ 'width': '150px'}}>Transfer-Datum</td>
                        <td>{ date_getDateTimeString(data.scanData.info.transferDate, true) }</td>
                    </tr>
                    <tr>
                        <td style={{ 'width': '150px'}}>Mail an Kunde</td>
                        <td>{ data.scanData.info.mailToCustomer ? 'ja, an: ' + data.scanData.info.mailToCustomer : 'nein' }</td>
                    </tr>
                    </tbody>
                </table>

            </div>
            <ScanView
                // data
                scanItemList={scanItemList}
                cust={props.cust}
                // settings
                useStatusStyle={false}
                printScannedValue={true}
                printDefaults={false}
                printActionButtons={false}
            />
            <div className={'card dotted plain'}>
                <h2>Statistik</h2>
                <Statistics
                    enrichedView={data.enrichedView}
                    defaults={props.cust.defaults}
                    items={data.scanData.data}
                />
            </div>
            <div className={"buttonBar"}>
                <button
                    className={'btn btn-primary bold'}
                    onClick={() => {
                        props.navigate('/history/list')
                    }}
                >
                    <FiArrowLeftCircle /> zur&uuml;ck
                </button>
            </div>
        </>
    );
}

export default HistoryView;
