import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//  ---
//  Page/Component Logout
//  ---
const Logout = () => {
    // ---
    // define vars we need
    // ---
    const navigate = useNavigate();

    // ---
    // Destroy local storage data and redirect to login page
    // ---
    useEffect(() => {
        localStorage.clear();
        navigate('/');
    });

    // ---
    // We do not render anything, but react needs a return to be defined.
    // ---
    return (
        <></>
    );
}

export default Logout;
