/* --- --- --- --- ---
    USER SERVICE
   --- --- --- --- ---  */

//  import used services
import { storage_load } from './storage-service';

//  ---
//  provide user object from storage
//  ---
export function user_loadFromStorage() {
    return storage_load('userData');
}

//  ---
//  validate user object
//  ---
export function user_validate(appUser, navigate) {
    // prepare vars for further check
    let userName   = (appUser !== null && typeof(appUser.name)    !== 'undefined') ? appUser.name    : false;
    let userNumber = (appUser !== null && typeof(appUser.number)  !== 'undefined') ? appUser.number  : false;
    let userEmail  = (appUser !== null && typeof(appUser.email)   !== 'undefined') ? appUser.email   : false;
    let userRef    = (appUser !== null && typeof(appUser.hashref) !== 'undefined') ? appUser.hashref : false;

    // return boolean
    if (userName   !== false &&
        userNumber !== false &&
        userEmail  !== false &&
        userRef    !== false
    ) {
        return true;
    } else {
        navigate('/login');
    }
}
