import React from 'react';

//  ---
//  Component Spinner
//  ---
const Spinner = (props) => {
    return (
        <div id={"spinner"} className={props.isLoading ? ' active' : ''}>
            <div className="loading">
            </div>
        </div>
    );
}

export default Spinner;
