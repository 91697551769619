/* --- --- --- --- ---
    rest service
   --- --- --- --- ---  */

import axios from 'axios';
import Md5 from 'md5-hash';

//  import used services
import { storage_write, storage_load } from './storage-service';

//  ---
//  process app users login
//  ---
export const rest_processLogin = (login, password, callback) => {
    axios({
        url: process.env.REACT_APP_ERP_API_URL+'/api/user/checkCredentials/',
        method: 'POST',
        timeout: 15000,
        data: {
            'login': login,
            'pass': Md5(password)
        },
        headers: {
            'Content-Type': 'text/plain',
            'Access-Control-Allow-Origin': '*'
        }
    })
    .then(response => {
        // set up the users local stored data
        storage_write('userData', response.data);
        callback(true);
    })
    .catch(error => {
        // callback(error.response);
        callback((error.response) ? error.response : [((error) ? error : 'Leider keine weiteren Informationen verfügbar!')]);
    });
}

//  ---
//  load customers default values from backend
//  ---
export const rest_getDefaults = (appUser, customerKey, callback) => {
    axios({
        url: process.env.REACT_APP_ERP_API_URL+'/api/customers/getDefaults/',
        method: 'POST',
        timeout: 30000,
        data: {
            userNumber: appUser.number.toString(),
            userReference:  appUser.hashref.toString(),
            customerNumber: customerKey
        },
        headers: {
            'Content-Type': 'text/plain',
            'Access-Control-Allow-Origin': '*'
        }
    })
    .then(response => {
        // get data from local storage
        let data = storage_load('fullStorageValueData');
        // add response to data
        if (!data[customerKey.toString()]) { data[customerKey.toString()] = {}; }
        data[customerKey.toString()] = response.data;
        // write data to local storage
        storage_write('fullStorageValueData', data);
        callback(true);
    })
    .catch(error => {
        // callback(error.response);
        callback((error.response) ? error.response : [((error) ? error : 'Leider keine weiteren Informationen verfügbar!')]);
    });
}

//  ---
//  load customers history from backend
//  ---
export const rest_getHistory = (appUser, customerKey, callback) => {
    axios({
        url: process.env.REACT_APP_ERP_API_URL+'/api/customers/getHistory/',
        method: 'POST',
        timeout: 30000,
        data: {
            userNumber: appUser.number.toString(),
            userReference:  appUser.hashref.toString(),
            customerNumber: customerKey
        },
        headers: {
            'Content-Type': 'text/plain',
            'Access-Control-Allow-Origin': '*'
        }
    })
    .then(response => {
        // get data from local storage
        let data = storage_load('transferHistory');
        // add response to data
        if (!data[customerKey.toString()]) { data[customerKey.toString()] = {}; }
        data[customerKey.toString()] = response.data;
        // write data to local storage
        storage_write('transferHistory', data);
        callback(true);
    })
    .catch(error => {
        // callback(error.response);
        callback((error.response) ? error.response : [((error) ? error : 'Leider keine weiteren Informationen verfügbar!')]);
    });
}

//  ---
//  load customers list from backend
//  ---
export const rest_getCustomerList = (appUser, callback) => {
    axios({
        url: process.env.REACT_APP_ERP_API_URL+'/api/customers/getList/',
        method: 'POST',
        timeout: 30000,
        data: {
            userNumber: appUser.number.toString(),
            userReference:  appUser.hashref.toString()
        },
        headers: {
            'Content-Type': 'text/plain',
            'Access-Control-Allow-Origin': '*'
        }
    })
    .then(response => {
        // write data to local storage
        storage_write('customerList', response.data);
        callback(true);
    })
    .catch(error => {
        // callback(error.response);
        callback((error.response) ? error.response : [((error) ? error : 'Leider keine weiteren Informationen verfügbar!')]);
    });
}

//  ---
//  request password reset link
//  ---
export const rest_requestPasswordResetLink = (login, callback) => {
    axios({
        url: process.env.REACT_APP_ERP_API_URL+'/api/user/requestPassword/',
        method: 'POST',
        timeout: 15000,
        data: {
            login: login
        },
        headers: {
            'Content-Type': 'text/plain',
            'Access-Control-Allow-Origin': '*'
        }
    })
    .then(response => {
        callback(true);
    })
    .catch(error => {
        // callback(error.response);
        callback((error.response) ? error.response : [((error) ? error : 'Leider keine weiteren Informationen verfügbar!')]);
    });
}

//  ---
//  set new password
//  ---
export const rest_setNewPassword = (login, cryptedPassword, reference, callback) => {
    axios({
        url: process.env.REACT_APP_ERP_API_URL+'/api/user/setPassword/',
        method: 'POST',
        timeout: 15000,
        data: {
            login: login,
            newPassword: cryptedPassword,
            passwordReference:  reference
        },
        headers: {
            'Content-Type': 'text/plain',
            'Access-Control-Allow-Origin': '*'
        }
    })
    .then(response => {
        callback(true);
    })
    .catch(error => {
        // callback(error.response);
        callback((error.response) ? error.response : [((error) ? error : 'Leider keine weiteren Informationen verfügbar!')]);
    });
}

//  ---
//  send scan to consi-backend
//  ---
export const rest_sendScanToBackend = (appUser, data, callback) => {
    if (appUser.testAccount === "1") {
        callback([
            'TESTACCOUNT',
            'Der Scan wird nicht übertragen!'
        ]);
    } else {
        axios({
            url: process.env.REACT_APP_ERP_API_URL+'/api/scan/save/',
            method: 'POST',
            timeout: 30000,
            data: {
                accountId: appUser.accountId,
                userNumber: appUser.number,
                userReference: appUser.hashref,
                customerNumber: data.customer.key,
                scanTimeStamp: data.timeStamp,
                scanData: data.scanData,
                mailToCustomer: data.mailToCustomer,
                mailToSalesAgent: data.mailToSalesRepresentative,
                appVersion: data.appVersion
            },
            headers: {
                'Content-Type': 'text/plain',
                'Access-Control-Allow-Origin': '*'
            }
        })
        .then(response => {
            callback(true);
        })
        .catch(error => {
            // callback(error.response);
            callback((error.response) ? error.response : [((error) ? error : 'Leider keine weiteren Informationen verfügbar!')]);
        });
    }
}

//  ---
//  request sending default values mail via backend
//  ---
export const rest_sendDefaults = (appUser, data, callback) => {
    if (appUser.testAccount === "1") {
        callback([
            'TESTACCOUNT',
            'Die Anforderung wird nicht ausgeführt!'
        ]);
    } else {
        axios({
            url: process.env.REACT_APP_ERP_API_URL+'/api/customer/sendDefaultValuesMail/',
            method: 'POST',
            timeout: 30000,
            data: {
                accountId: appUser.accountId,
                userNumber: appUser.number,
                userReference: appUser.hashref,
                customerNumber: data.customer.key,
                mailToCustomer: data.mailToCustomer,
                mailToSalesRepresentative: data.mailToSalesRepresentative
            },
            headers: {
                'Content-Type': 'text/plain',
                'Access-Control-Allow-Origin': '*'
            }
        })
        .then(response => {
            callback(true);
        })
        .catch(error => {
            // callback(error.response);
            callback((error.response) ? error.response : [((error) ? error : 'Leider keine weiteren Informationen verfügbar!')]);
        });
    }
}
