import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

//  import styles
import '../../styles/pages/public.scss';

//  import components
import Spinner from '../../components/spinner';

//  import services
import { rest_requestPasswordResetLink } from '../../services/rest-service';

//  ---
//  Page/Component Login
//  ---
const PasswordRequest = () => {
    const navigate = useNavigate();

    //  define some 'global' vars
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isSent, setIsSent ] = useState(false);

    // ---
    // handle input form field changes
    // ---
    const [inputText, setInputText] = useState({
        username: "",
    });
    const onFormInputFieldChange = e => {
        setInputText({
            ...inputText,
            [e.target.name]: e.target.value,
        })
    }

    // ---
    // handle login & form errors
    // ---
    const [ infoBox, setInfoBox ] = useState({
        statusCode: '',
        infoText: '',
        visibility: false
    });
    const resetFormError = () => {
        setInfoBox({
            statusCode: '',
            infoText: '',
            visibility: false
        });
    }
    const handleFormError = error => {
        // set custom info texts based on statusCode
        const newInfoText = (error) => {
            switch (error.status.toString()) {
                case '403':
                    return 'Zugriff verweigert';
                default:
                    return error.statusText.toString()
            }
        }
        // update info box object
        setInfoBox({
            statusCode: error.status.toString(),
            infoText: newInfoText(error),
            visibility: true
        });
    }

    //  ---
    //  send form
    //  ---
    const submitRequest = e => {
        e.preventDefault();
        resetFormError();
        if ('' !== inputText.username.trim()) {
            setIsLoading(true);
            rest_requestPasswordResetLink(
                inputText.username.toString().trim(),
                function(response) {
                    if (true !== response) {
                        handleFormError(response);
                        setIsLoading(false);
                        setInputText({
                            ...inputText,
                            'username': '',
                        })
                    } else {
                        setIsLoading(false);
                        setIsSent(true);
                    }
                }
            );
        }
    }

    //  ---
    //  back to login page
    //  ---
    const backToLogin = () => {
        navigate('/login');
    }

    //  ---
    //  return
    //  ---
    return (
        <div id="passwordRequestPage">
            <div>
                <p><img src="../images/medi-logo.192.png" alt={process.env.REACT_APP_NAME} /></p>
            </div>
            <div className={'loginProcessInfoBox' + (infoBox.visibility ? ' active' : '')}>
                <h2>{infoBox.statusCode}</h2>
                <p>{infoBox.infoText}</p>
            </div>
            <Spinner isLoading={isLoading} />
            <div className={isLoading || isSent ? 'hidden' : ''}>
                <form onSubmit={submitRequest}>
                    <p>
                        Geben Sie hier <u>Ihren Login</u> ein und<br />senden das Formular ab.<br />
                        <br />
                        Sie bekommen im Anschluss eine E-Mail<br />mit einem Link zum zur&uuml;cksetzen<br />Ihres Passworts zugesendet.</p>
                    <p>
                        <input
                            type="text"
                            name="username"
                            className="input-text"
                            value={inputText.username}
                            placeholder="Login"
                            onChange={onFormInputFieldChange}
                        />
                    </p>
                    <p>
                        <input
                            type="submit"
                            className="btn btn-primary bold"
                            value="senden"
                            style={{'width':'110px'}}
                            onClick={submitRequest}
                        />
                        <input
                            type="button"
                            style={{'width':'110px'}}
                            className="btn btn-secondary bold"
                            value="zur&uuml;ck"
                            onClick={backToLogin}
                        />
                    </p>
                </form>
            </div>
            <div className={"successBox "+(!isSent ? 'hidden' : '')}>
                <p>
                    Ihre Anforderung wurde versendet.<br />
                    Bitte pr&uuml;fen Sie ihren Mail-Eingang.
                </p>
                <input
                    type="button"
                    style={{'width':'130px'}}
                    className="btn btn-secondary bold"
                    value="zur Anmedlung"
                    onClick={backToLogin}
                />
            </div>
        </div>
    );
}

export default PasswordRequest;
