import React, { useState } from 'react';
import { FiRefreshCw, FiDownloadCloud } from 'react-icons/fi';

//  import header styles
import '../styles/components/header.scss';

//  import page-components
import CustomerAddress from '../pages/restricted/customer/address';
import Spinner from './spinner';

//  import services
import { search_loadResults, search_clearResults, search_writeResults} from '../services/search-service';

//  ---
//  Component Header
//  ---
const Header = (props) => {
    //  ---
    //  the search field value
    //  ---
    const searchField = useState({'value':''});

    //  ---
    //  filter list by search
    //  ---
    const filterCustomerList = (e) => {
        // clear local storage
        search_clearResults();
        // prepare array of search values to compare against
        let searchValues = e.target.value.trim().replace(/\//, ' ').trim().split(" ");
        // perform search
        Object.keys(props.customerList).map((customerKey) => {
            // prepare customers values to a single string, so we can compare it against the search values
            let customerValuesAsString = Object.values(props.customerList[customerKey]).join(' ').replace(/\s\s+/g, ' ').toLowerCase();
            // compare against search values
            let hit = true;
            for (let x=0; x<searchValues.length; x++) {
                if (customerValuesAsString.indexOf(searchValues[x].toLowerCase()) === -1) {
                    hit = false;
                }
            }
            // add search result as css class to customer object
            props.customerList[customerKey]['css'] = (!hit) ? 'hidden' : '';
            // write result to localStorage
            search_writeResults(props.customerList);
            return props.customerList;
        });
        props.setCustomerList(search_loadResults());
    }

    //  ---
    //  return
    //  ---
    return (
        <div id={"header"} onClick={() => { props.setMenuStatus(false); }}>
            <table>
                <tbody>
                    <tr>
                        {/** --- customer address --- **/}
                        <td className={(props.isLoading ? 'frozen' : '')}>
                            { !['customer-list','transfer-list','info-page','faq'].includes(props.page) && props.cust && <CustomerAddress
                                cust={props.cust}
                                showAddress={true}
                                showCustomerNumber={true}
                                showCustomerEMail={true}
                            /> }
                            { ['customer-list'].includes(props.page) && !props.isLoading && (
                                <div className={"searchBar"}>
                                    <input className="input-text customerSearch" onChange={filterCustomerList} value={searchField.value} placeholder="Suche" />
                                </div>
                            )}
                        </td>

                        {/** --- spinner and buttons --- **/}
                        <td style={{'textAlign':'center'}}>
                            <Spinner isLoading={props.isLoading} />
                            { ![
                                    'history-list',
                                    'customer-list',
                                    'transfer-list',
                                    'info-page',
                                    'faq'
                                ].includes(props.page) && !props.isLoading && props.isOnline && props.cust && (
                                <button
                                    className={"btn btn-secondary refreshStorageButton"}
                                    onClick={() => { props.loadCustomerDefaults(props.cust.key); }}
                                >
                                    {  (props.cust.defaults &&
                                        Object.keys(props.cust.defaults).length > 0) &&
                                        (
                                            <>
                                                <FiRefreshCw /><br />{
                                                    [ 'scan-form' ].includes(props.page)
                                                    ? 'Sollwerte'
                                                    : 'Titel'
                                                } neu laden
                                            </>
                                        )
                                    }
                                    {  (!props.cust.defaults ||
                                        Object.keys(props.cust.defaults).length === 0) &&
                                        (
                                            <>
                                                <FiDownloadCloud /><br />{
                                                    [ 'scan-form' ].includes(props.page)
                                                    ? 'Sollwerte'
                                                    : 'Titel'
                                                } laden
                                            </>
                                        )
                                    }
                                </button>
                            ) }
                            { ['history-list'].includes(props.page) && !props.isLoading && props.isOnline && props.cust && (
                                <>
                                    <button
                                        className={"btn btn-secondary refreshStorageButton"}
                                        onClick={() => { props.loadCustomersHistory(props.cust.key); }}
                                    >
                                        {  (props.cust.history &&
                                                Object.keys(props.cust.history).length > 0) &&
                                            (
                                                <>
                                                    <FiRefreshCw /><br />Historie neu laden
                                                </>
                                            )
                                        }
                                        {  (!props.cust.history ||
                                                Object.keys(props.cust.history).length === 0) &&
                                            (
                                                <>
                                                    <FiDownloadCloud /><br />Historie laden
                                                </>
                                            )
                                        }
                                    </button>
                                </>
                            ) }
                            { ['customer-list'].includes(props.page) && !props.isLoading && props.isOnline && (
                                <>
                                    <button
                                        className={"btn btn-secondary refreshStorageButton"}
                                        onClick={() => { props.loadCustomerList(); }}
                                    >
                                        {  (props.customerList &&
                                                Object.keys(props.customerList).length > 0) &&
                                            (
                                                <>
                                                    <FiRefreshCw /><br />Kundenliste neu laden
                                                </>
                                            )
                                        }
                                        {  (!props.customerList ||
                                                Object.keys(props.customerList).length === 0) &&
                                            (
                                                <>
                                                    <FiDownloadCloud /><br />Kundenliste laden
                                                </>
                                            )
                                        }
                                    </button>
                                </>
                            ) }
                        </td>

                        {/** --- information --- **/}
                        <td style={{'width':'120px'}} className={(props.isLoading ? 'frozen' : '')}>
                            <span className={'onlineInfoBox' + (props.isOnline ? ' online' : ' offline')} >
                                { props.isOnline ? 'online' : 'offline' }
                            </span>
                            <span className="testModusInfoBox">
                                { props.appUser.testAccount === "1" ? 'TESTMODUS' : '' }
                            </span>
                            <span className="appVersionNumberInfoBox">
                                v{ process.env.REACT_APP_VERSION_NUMBER }
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default Header;
