/* --- --- --- --- ---
    scan service
   --- --- --- --- ---  */

//  import used services
import { storage_load } from './storage-service';

//  ---
//  load history list of already transferred scans
//  ---
export function scan_loadHistoryList() {
    let data = storage_load('transferHistory')
    return data || {};
}

//  ---
//  validate given ean
//  ---
export function scan_validateEan(ean, mediEanRange) {
    let error = [];

    // 1st: trim ean
    ean = ean.trim();

    // 2nd: check length for exact 13 chars
    if (ean.length !== 13) {
        error.push("Der EAN muss exakt 13 Zeichen lang sein!");
        error.push("Übergebene Zeichenlänge: "+ean.length+' Zeichen.');
        return error;
    }

    // 3rd: calculate and check checksum
    let eanChars = ean.split('');
    let charError = false;
    let total = 0;
    let checkSum = 0;
    for (let x=0;x<13;x++) {
        // > prepare this char
        let thisChar = eanChars[x];
        // > prepare char as int
        let thisInt = parseInt(thisChar);
        // > check if this char is an int
        if (thisInt.toString() !== thisChar) {
            charError = true;
        } else {
            // > calculate the total for the checksum
            if (x < 12) {
                // odd
                if ((x+1)%2 !== 0) {
                    total = total + (thisInt); // *1
                }
                // even
                else {
                    total = total + (thisInt*3); // *3
                }
            } else {
                checkSum = thisInt;
            }
        }
    }
    // > calculate the checkSum out of the total
    let totalCheckSum = 10-parseInt(total.toString().substring(total.toString().length-1));
    totalCheckSum = (totalCheckSum < 0 || totalCheckSum === 10) ? 0 : totalCheckSum;
    if (false === charError && totalCheckSum !== checkSum) {
        error.push('EAN Checksummen-Fehler');
        error.push('EAN: '+ean);
        error.push('Checksumme: '+totalCheckSum+' <> '+checkSum);
        return error;
    }

    //  > do we have a char error?
    if (true === charError) {
        error.push('Der EAN '+ean+' enthält ungültige Zeichen!')
        return error;
    }

    // check for ean in medi ean range
    let mediEanRangeHit = false;
    for (let x=0; x<Object.keys(mediEanRange).length; x++) {
        if (ean.substring(0, mediEanRange[x].length) === mediEanRange[x]) {
            mediEanRangeHit = true;
        }
    }
    if (false === mediEanRangeHit) {
        error.push('Der EAN '+ean+' ist kein bekannter medi-EAN!')
        return error;
    }

    return true;
}

//  ---
//  prepare scan item properties for views and form
//  ---
export function scan_prepareScanItems(customer, page, fullDefaultValuesList, additionalIdentifier) {
    // 1st: use defaults as basic
    let eanList = (fullDefaultValuesList[customer.key]) ? fullDefaultValuesList[customer.key] : {};
    let eanListObjectKeys = Object.keys(eanList);
    for (let x=0; x<eanListObjectKeys.length; x++) {
        eanList[eanListObjectKeys[x]].currentQuantity = 0;
    }
    // 2nd: add items or update quantities in already set up defaults
    let scanItems = {};
    //  a) for "running" scan in -form and -preview
    if ([ 'scan-form', 'scan-preview' ].includes(page)) {
        scanItems = customer.scan;
    }
    //  b) for "historic" scan in history-view
    if ([ 'history-view', 'history-latest' ].includes(page)) {
        if (customer.history[additionalIdentifier]) {
            scanItems = customer.history[additionalIdentifier].data;
        }
    }
    //  c) for scan in transfer-view
    if ([ 'transfer-view' ].includes(page)) {
        if (customer.transfer[additionalIdentifier]) {
            scanItems = customer.transfer[additionalIdentifier];
        }
    }
    //  d) for preview in "send customer defaults"
    if ([ 'customer-send-defaults' ].includes(page)) {
        // no values
        scanItems = {};
    }
    // just for information
    if (![ 'scan-form', 'scan-preview', 'history-view', 'history-latest', 'transfer-view', 'customer-send-defaults' ].includes(page)) {
        console.log('todo: page '+page+' not defined to prepareScanItems');
    }
    //  x) add the quantities (and ean if necessary) to defaults
    //     also remember if this is a default ean or an additional added one
    let scanItemKeys = Object.keys(scanItems);
    for (let x=0; x<scanItemKeys.length; x++) {
        if (!eanList[scanItemKeys[x]]) {
            eanList[scanItemKeys[x]] = {};
            eanList[scanItemKeys[x]].ean = scanItemKeys[x];
            eanList[scanItemKeys[x]].quantity = 0; // default- | target-value
            eanList[scanItemKeys[x]].isDefault = false;
        } else {
            eanList[scanItemKeys[x]].isDefault = true;
        }
        eanList[scanItemKeys[x]].currentQuantity = scanItems[scanItemKeys[x]];
    }
    //  y) remove all items from list, having 0 quantity and 0 currentQuantity
    //     also fix not set currentQuantity
    let eanListKeys = Object.keys(eanList);
    for (let x=0; x<eanListKeys.length; x++) {
        //  fix current quantity if not set
        if (!eanList[eanListKeys[x]].currentQuantity || [ '', 'undefined', undefined ].includes(eanList[eanListKeys[x]].currentQuantity)) {
            eanList[eanListKeys[x]].currentQuantity = 0;
        }
        //  remove items having 0 quantity and 0 currentQuantity
        if (eanList[eanListKeys[x]].currentQuantity === 0 && eanList[eanListKeys[x]].quantity === 0
        ) {
            delete eanList[eanListKeys[x]];
        }
    }
    //  z) add
    //      - css-style,
    //      - default-texts,
    //      - etc.
    eanListKeys = Object.keys(eanList);
    for (let x=0; x<eanListKeys.length; x++) {
        //  >>> CSS STUFF
        eanList[eanListKeys[x]].stockCssClassName = scan_getCssClass(customer, eanList[eanListKeys[x]], eanList[eanListKeys[x]].currentQuantity);
        //  >>> TITLE STUFF
        if (['', undefined, 'undefined'].includes(eanList[eanListKeys[x]].articleTitle) ||
            (eanList[eanListKeys[x]].articleTitle && eanList[eanListKeys[x]].articleTitle.trim() === '')) {
            eanList[eanListKeys[x]].articleTitle = 'Unbekannter Artikel';
        }
        //  >>> IS DEFAULT
        eanList[eanListKeys[x]].isDefault = !!(customer.defaults[eanListKeys[x]]); // true : false
    }
    //  fix: for some pages we have to remove scanItems having no current quantity
    if ([ 'history-view', 'history-latest' ].includes(page)) {
        // but remove scanItems having no current quantity!
        let cleanedEanList = {...eanList};
        let cleanedEanListKeys = Object.keys(cleanedEanList);
        for (let x = 0; x < cleanedEanListKeys.length; x++) {
            if ([0, '', 'undefined', undefined].includes(cleanedEanList[cleanedEanListKeys[x]].currentQuantity)) {
                delete cleanedEanList[cleanedEanListKeys[x]];
            }
        }
        eanList = {...cleanedEanList};
    }
    return eanList;
}

//  ---
//  prepare css classes for ean cards in views and form based on quantity settings
//  ---
export function scan_getCssClass(customer, ean, currentQuantity) {
    let stockCssClassName = ''
    //  not in default list
    if (!customer.defaults ||
        !customer.defaults[ean.ean] ||
        !customer.defaults[ean.ean].quantity ||
        customer.defaults[ean.ean].quantity === 0
    ) {
        stockCssClassName = 'not-in-stock';
    } else {
        //  lower stock
        if (['', undefined, 'undefined'].includes(currentQuantity) ||
            ean.quantity > currentQuantity ||
            currentQuantity === 0
        ) {
            stockCssClassName = 'lower-stock';
        }
        //  greater stock
        if (!['', undefined, 'undefined'].includes(currentQuantity) && ean.quantity < currentQuantity) {
            stockCssClassName = 'greater-stock';
        }
        //  equal stock
        if (!['', undefined, 'undefined'].includes(currentQuantity) && ean.quantity === currentQuantity) {
            stockCssClassName = 'equal-stock';
        }
    }
    return stockCssClassName;
}