import React, { useState, useEffect } from 'react';

//  import style
import '../styles/components/statistics.scss';

//  ---
//  Component Statistics
//  ---
const Statistics = (props) => {
    //  ---
    //  calculate some basic stuff
    //  ---
    const [ unitSum ] = useState(calculateSumOfUnits);
    function calculateSumOfUnits() {
        let count = 0;
        let itemKeys = Object.keys(props.items);
        for (let x=0; x<itemKeys.length; x++) {
            count = count + props.items[itemKeys[x]];
        }
        return count;
    }
    const [ enriched, setEnriched ] = useState(props.enrichedView ? calculateEnrichedStatisticData : prepareDefaultStatisticObject);
    function prepareDefaultStatisticObject() {
        return {
            'invalid': {
                'ean': '-',
                'units': '-'
            },
            'overage': {
                'ean': '-',
                'units': '-'
            },
            'congruence': {
                'ean': '-',
                'units': '-'
            },
            'default': {
                'ean': '-',
                'units': '-'
            }
        };
    }
    function calculateEnrichedStatisticData() {
        //  define used vars
        let EanInScan = Object.keys(props.items).length;
        let UnitInScan = unitSum;

        //  calculate : defaults
        let EanDefault = 0;
        let UnitDefault = 0;
        let defaultObjectKeys = Object.keys(props.defaults);
        for (let x=0; x<defaultObjectKeys.length; x++) {
            // add unit quantity
            UnitDefault = UnitDefault + props.defaults[defaultObjectKeys[x]].quantity;
            // only count eans having some quantity
            EanDefault = EanDefault + (props.defaults[defaultObjectKeys[x]].quantity > 0 ? 1 : 0);
        }

        //  calculate : +/-
        let EanOverage = EanInScan - EanDefault;
        let UnitOverage = UnitInScan - UnitDefault;

        //  calculate: invalid
        let EanInvalid = 0;
        let UnitInvalid = 0;
        let inScanObjectKeys = Object.keys(props.items);
        for (let x=0; x<inScanObjectKeys.length; x++) {
            if (!props.defaults[inScanObjectKeys[x]] || (props.defaults[inScanObjectKeys[x]] && props.defaults[inScanObjectKeys[x]].quantity === 0)) {
                EanInvalid = EanInvalid + 1;
                UnitInvalid = UnitInvalid + props.items[inScanObjectKeys[x]];
            }
        }

        //  calculate congruence
        let EanCongruence = Math.round((EanInScan - EanInvalid) / (EanDefault / 100));
        let UnitCongruence =  Math.round((UnitInScan - UnitInvalid) / (UnitDefault / 100));

        //  return as object
        return {
            'invalid': {
                'ean': EanInvalid,
                'units': UnitInvalid
            },
            'overage': {
                'ean': EanOverage,
                'units': UnitOverage
            },
            'congruence': {
                'ean': EanCongruence,
                'units': UnitCongruence
            },
            'default': {
                'ean': EanDefault,
                'units': UnitDefault
            }
        }
    }

    //  ---
    //  some effect
    //  ---
    useEffect(() => {
        setEnriched(props.enrichedView ? calculateEnrichedStatisticData : prepareDefaultStatisticObject);
    // eslint-disable-next-line
    }, [ props.defaults, props.enrichedView ]);

    //  ---
    //  return
    //  ---
    return (
        <div id={"statistics"}>
            <table className={'innerGrid'} style={{'width':'auto', 'maxWidth':'520px'}}>
                <thead>
                    <tr>
                        <th></th>
                        <th>in Scan</th>
                        { props.enrichedView && (<th>Sollwert</th>) }
                        { props.enrichedView && (<th>+/-</th>) }
                        { props.enrichedView && (<th>Ung&uuml;ltig</th>) }
                        { props.enrichedView && (<th>Deckung</th>) }
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={"title"}>EANs</td>
                        <td className={'centered'}>{ Object.keys(props.items).length }</td>
                        { props.enrichedView && (<td className={'centered'}>{ enriched.default.ean }</td>) }
                        { props.enrichedView && (<td className={'centered'}>{ enriched.overage.ean }</td>) }
                        { props.enrichedView && (<td className={'centered'}>{ enriched.invalid.ean }</td>) }
                        { props.enrichedView && (<td className={'centered '+(
                            (enriched.congruence.ean <= 100 &&  enriched.congruence.ean > 95)
                            ? 'congruence-ok'
                            : (enriched.congruence.ean <= 95 &&  enriched.congruence.ean > 90)
                                    ? 'congruence-warning-light'
                                    : (enriched.congruence.ean <= 90 &&  enriched.congruence.ean > 85)
                                        ? 'congruence-warning-dark'
                                        : 'congruence-warning-fatal'
                        )}>{ enriched.congruence.ean } %</td>) }
                    </tr>
                    <tr>
                        <td className={"title"}>Einheiten</td>
                        <td className={'centered'}>{ unitSum }</td>
                        { props.enrichedView && (<td className={'centered'}>{ enriched.default.units }</td>) }
                        { props.enrichedView && (<td className={'centered'}>{ enriched.overage.units }</td>) }
                        { props.enrichedView && (<td className={'centered'}>{ enriched.invalid.units }</td>) }
                        { props.enrichedView && (<td className={'centered '+(
                            (enriched.congruence.units <= 100 &&  enriched.congruence.units > 95)
                                ? 'congruence-ok'
                                : (enriched.congruence.units <= 95 &&  enriched.congruence.units > 90)
                                    ? 'congruence-warning-light'
                                    : (enriched.congruence.units <= 90 &&  enriched.congruence.units > 85)
                                        ? 'congruence-warning-dark'
                                        : 'congruence-warning-fatal'
                        )}>{ enriched.congruence.units } %</td>) }
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default Statistics;
