import React from 'react';
import { FiCheck } from "react-icons/fi";

//  ---
//  Component AlertWindow
//  ---
const AlertWindow = (props) => {
    //  ---
    //  return
    //  ---
    return (
        <div className={'alertWindow ' + (props.settings.visibility ? 'active' : '')+' '+(props.settings.cssClassAddOn)} >
            <h2>{ props.settings.headline }</h2>
            <div>{ Object.keys(props.settings.messages).map(key => {
                return (<p key={'confirmation-window-message-'+key}>{props.settings.messages[key]}</p>);
            })
            }</div>
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td style={{'textAlign':'center','width':'100%'}}>
                                <button
                                    className={"btn btn-primary bold"}
                                    style={{'width':'140px'}}
                                    onClick={props.settings.actions.submit.callback}
                                ><FiCheck /> { props.settings.actions.submit.title}</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default AlertWindow;
