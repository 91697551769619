import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

//  import styles
import '../../styles/pages/restricted.scss';

//  import services
import {
    user_loadFromStorage,
    user_validate
} from '../../services/user-service';
import {
    customer_loadListFromStorage,
    customer_loadDefaultsFromStorage,
    customer_init
} from '../../services/customer-service';
import {
    scan_loadHistoryList
} from '../../services/scan-service';
import {
    rest_getCustomerList,
    rest_getDefaults,
    rest_getHistory
} from '../../services/rest-service';
import {
    overlay_initialize
} from '../../services/overlay-service';
import {
    storage_load
} from '../../services/storage-service';

//  import pages
import CustomerList from './customer/list';
import CustomerSendDefaults from './customer/send-defaults';
import Faq from './faq/list';
import HistoryLatest from './history/latest';
import HistoryList from './history/list';
import HistoryView from './history/view';
import ScanForm from './scan/form';
import ScanPreview from './scan/preview';
import TransferList from './transfer/list';
import TransferView from './transfer/view';
import InfoPage from './info/info';

//  import components
import Header from '../../components/header';
import Menu from '../../components/menu';
import Overlay from '../../components/overlay';
import axios from "axios";

//  ---
//  Page/Component App
//  ---
const App = (props) => {
    //  connectivity
    function checkConnectivity() {

        let urlToCheckForConnectivity = window.location.origin + '/connectivity.html?rand=' + uuidv4();
        // console.log(urlToCheckForConnectivity);

        // if navigator tells us, we`re offline, then use this state
        if (!window.navigator.onLine) {
            setIsOnline(false);
        } else {
            // check some url to find out if we are online or offline
            axios({
                url: urlToCheckForConnectivity,
                method: 'GET',
                timeout: 3000,
            })
                .then(response => {
                    if (response.status === 200) {
                        setIsOnline(true);
                    } else {
                        setIsOnline(false);
                    }
                })
                .catch(() => {
                    setIsOnline(false);
                });
        }
    }

    // basics
    const { state } = useLocation();
    const navigate = useNavigate();

    //  define some 'global' vars
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isOnline, setIsOnline ] = useState(checkConnectivity);
    const [ menuStatus, setMenuStatus ] = useState(false);

    //  lists
    const [ customerList, setCustomerList ] = useState(storage_load('customerList'));
    const [ fullDefaultValuesList, setFullDefaultValuesList ] = useState(storage_load('fullStorageValueData'));
    const [ historyList, setHistoryList ] = useState(storage_load('transferHistory'));
    const [ runningScans, setRunningScans ] = useState(storage_load('scans'));
    const [ transferList, setTransferList ] = useState(storage_load('transferList'));

    //  customer
    const [ cust, setCust ] = useState(customer_init(storage_load('customerKey'), customerList, historyList, fullDefaultValuesList, runningScans, transferList, 'app.js:start'));

    //  the app user
    const [ appUser ] = useState(user_loadFromStorage);

    //  set up overlay
    const [ overlaySettings, setOverlaySettings ] = useState(overlay_initialize);

    //  (re-)load data
    function loadCustomerDefaults(customerKey) {
        setIsLoading(true);
        rest_getDefaults(appUser, customerKey, (response) => {
            if (true !== response) {
                setIsLoading(false);
            } else {
                // refresh vars
                setFullDefaultValuesList(customer_loadDefaultsFromStorage);
                setIsLoading(false);
                // reload this page again (unless it is the customer-list)
                if (![ 'customer-list' ].includes(props.page)) {
                    window.location.reload();
                }
            }
        });
    }
    function loadCustomersHistory(customerKey) {
        setIsLoading(true);
        rest_getHistory(appUser, customerKey, (response) => {
            if (true !== response) {
                setOverlaySettings({
                    visible: true,
                    type: 'alert-window',
                    headline: 'Es sind Fehler aufgetreten!',
                    messages: response,
                    intensity: 'medium',
                    actions: {
                        submit: {
                            title: 'ok',
                            callback: () => {
                                setOverlaySettings(overlay_initialize)
                            }
                        }
                    }
                });
                setIsLoading(false);
            } else {
                // refresh vars
                setHistoryList(scan_loadHistoryList);
                setIsLoading(false);
            }
        });
    }
    function loadCustomerList() {
        setIsLoading(true);
        rest_getCustomerList(appUser, (response) => {
            if (true !== response) {
                setOverlaySettings({
                    visible: true,
                    type: 'alert-window',
                    headline: 'Es sind Fehler aufgetreten!',
                    messages: response,
                    intensity: 'medium',
                    actions: {
                        submit: {
                            title: 'ok',
                            callback: () => {
                                setOverlaySettings(overlay_initialize)
                            }
                        }
                    }
                });
                setIsLoading(false);
            } else {
                // refresh vars
                setCustomerList(customer_loadListFromStorage);
                setIsLoading(false);
            }
        });
    }

    //  ---
    //  some effects
    //  ---
    useEffect(() => {
        user_validate(appUser, navigate);
    }, // eslint-disable-next-line
    [ appUser ]);

    useEffect(() => {
        //  refresh customer list from storage, i.e. to clean up search value
        setCustomerList(storage_load('customerList'));
        //  scroll this page (-content) to top on load
        if (
            props.page.indexOf('-list') !== -1 ||
            [ 'scan-preview', 'history-latest', 'transfer-view', 'customer-send-defaults' ].includes(props.page)
        ) {
            document.getElementById("content").scrollTo({ behavior: 'smooth', top: 0 });
        }
        // check connectivity
        checkConnectivity();
    }, // eslint-disable-next-line
    [ props.page ]);

    useEffect(() => {
        // need to re-initialize customer object
        setCust(customer_init(storage_load('customerKey'), customerList, historyList, fullDefaultValuesList, runningScans, transferList, '@useEffect in app.js for runningScans'));
    }, // eslint-disable-next-line
    [ runningScans ]);

    // call connectivity check once at first rendering
    useEffect(() => {
        const connectivityTimer = setInterval(() => {
            checkConnectivity();
        }, 15000);
        return () => clearTimeout(connectivityTimer);
    }, []);

    // ---
    // event listeners
    // ---
    window.addEventListener("offline", () => checkConnectivity(), false);
    window.addEventListener("online", () => checkConnectivity(), false);
    window.addEventListener('storage', () => {
        // app user
        const appUser = user_loadFromStorage();
        user_validate(appUser, navigate);
    });

    // ---
    // return
    // ---
    return (
        <>
            {   /** --- PAGE-AREA - HEADER --- */}
            <Header
                //  basics
                isOnline={isOnline}                             // state of online/offline
                isLoading={isLoading}                           // if a spinner should run
                navigate={navigate}                             // to avoid multiple calls of this basic function
                //  data
                customerList={customerList}                     // necessary for search filter
                appUser={appUser}                               // to know if this user is a test-user
                cust={cust}                                     // the full data object of current selected customer
                page={props.page}                               // which page is currently called
                //  functions
                setIsLoading={setIsLoading}                     // to toggle spinner
                setCustomerList={setCustomerList}               // set up filtered customer list
                setMenuStatus={setMenuStatus}                   // toggle menu
                loadCustomerDefaults={loadCustomerDefaults}     // (re-)load default values for current selected customer
                loadCustomersHistory={loadCustomersHistory}     // (re-)load history data for current selected customer
                loadCustomerList={loadCustomerList}             // (re-)load the whole customer list
            />

            {   /** --- PAGE-AREA - MENU --- */}
            <Menu
                //  basics
                isLoading={isLoading}                           // if a spinner should run
                menuStatus={menuStatus}                         // to handle view/hide of menu
                navigate={navigate}                             // to avoid multiple calls of this basic function
                //  data
                cust={cust}
                //  functions
                setIsLoading={setIsLoading}                     // to toggle spinner
                setMenuStatus={setMenuStatus}                   // toggle menu
                setOverlaySettings={setOverlaySettings}         // set up overlay config
            />

            {   /** --- PAGE-AREA - CONTENT --- */}
            <div
                id={"content"}
                onClick={() => { setMenuStatus(false); }}
                className={isLoading ? 'frozen' : ''}
            >

                {   /** --- PAGE - CUSTOMER LIST --- */}
                { ((props.page && props.page === 'customer-list') || !props.page) &&
                    <CustomerList
                        //  basics
                        isOnline={isOnline}                             // state of online/offline
                        isLoading={isLoading}                           // if a spinner should run
                        navigate={navigate}                             // to avoid multiple calls of this basic function
                        //  data
                        cust={cust}                                     // the full data object of current selected customer
                        customerList={customerList}                     // necessary for search filter
                        historyList={historyList}                       // necessary to initialize a customer object
                        fullDefaultValuesList={fullDefaultValuesList}   // necessary to initialize a customer object
                        page={props.page}                               // which page is currently called
                        runningScans={runningScans}                     // necessary to initialize a customer object
                        transferList={transferList}                     // necessary to initialize a customer object
                        //  functions
                        setCust={setCust}                               // to set up current used customer
                        setIsLoading={setIsLoading}                     // to toggle spinner
                        loadCustomerDefaults={loadCustomerDefaults}
                        loadCustomersHistory={loadCustomersHistory}
                    />
                }

                {   /** --- PAGE - CUSTOMER SEND DEFAULTS --- */}
                { ((props.page && props.page === 'customer-send-defaults') || !props.page) &&
                    <CustomerSendDefaults
                        //  basics
                        isOnline={isOnline}                             // state of online/offline
                        isLoading={isLoading}                           // if a spinner should run
                        navigate={navigate}                             // to avoid multiple calls of this basic function
                        //  data
                        cust={cust}                                     // the full data object of current selected customer
                        appUser={appUser}                               // necessary to know the email of this user
                        fullDefaultValuesList={fullDefaultValuesList}   // necessary to initialize a customer object
                        page={props.page}                               // which page is currently called
                        //  functions
                        setIsLoading={setIsLoading}                     // to toggle spinner
                        setOverlaySettings={setOverlaySettings}         // set up overlay config
                    />
                }

                {   /** --- PAGE - FAQ --- */}
                { (props.page && props.page === 'faq') &&
                    <Faq
                    />
                }

                {   /** --- PAGE - HISTORY LATEST --- */}
                { (props.page && props.page === 'history-latest') &&
                    <HistoryLatest
                        //  basics
                        navigate={navigate}                             // to avoid multiple calls of this basic function
                        //  data
                        cust={cust}                                     // the full data object of current selected customer
                        page={props.page}                               // which page is currently called
                        fullDefaultValuesList={fullDefaultValuesList}   // necessary to initialize a customer object
                    />
                }

                {   /** --- PAGE - HISTORY LIST --- */}
                { (props.page && props.page === 'history-list') &&
                    <HistoryList
                        //  basics
                        isLoading={isLoading}                           // if a spinner should run
                        navigate={navigate}                             // to avoid multiple calls of this basic function
                        //  data
                        cust={cust}                                     // the full data object of current selected customer
                        page={props.page}                               // which page is currently called
                    />
                }

                {   /** --- PAGE - HISTORY VIEW --- */}
                { (props.page && props.page === 'history-view') &&
                    <HistoryView
                        //  basics
                        navigate={navigate}                             // to avoid multiple calls of this basic function
                        //  data
                        cust={cust}                                     // the full data object of current selected customer
                        fullDefaultValuesList={fullDefaultValuesList}   // necessary to initialize a customer object
                        page={props.page}                               // which page is currently called
                        stateProps={state}                              // data the history list will provide via state property
                    />
                }

                {   /** --- PAGE - SCAN FORM --- */}
                { (props.page && props.page === 'scan-form') &&
                    <ScanForm
                        //  basics
                        navigate={navigate}                             // to avoid multiple calls of this basic function
                        //  data
                        cust={cust}                                     // the full data object of current selected customer
                        customerList={customerList}                     // necessary for search filter
                        historyList={historyList}                       // necessary to initialize a customer object
                        fullDefaultValuesList={fullDefaultValuesList}   // necessary to initialize a customer object
                        page={props.page}                               // which page is currently called
                        runningScans={runningScans}                     // necessary to initialize a customer object
                        transferList={transferList}                     // necessary to initialize a customer object
                        //  functions
                        setCust={setCust}                               // to set up current used customer
                        setOverlaySettings={setOverlaySettings}         // set up overlay config
                        setRunningScans={setRunningScans}               // current local stored scans
                    />
                }

                {   /** --- PAGE - SCAN PREVIEW --- */}
                { (props.page && props.page === 'scan-preview') &&
                    <ScanPreview
                        //  basics
                        navigate={navigate}                             // to avoid multiple calls of this basic function
                        //  data
                        cust={cust}                                     // the full data object of current selected customer
                        customerList={customerList}                     // necessary for search filter
                        historyList={historyList}                       // necessary to initialize a customer object
                        fullDefaultValuesList={fullDefaultValuesList}   // necessary to initialize a customer object
                        page={props.page}                               // which page is currently called
                        runningScans={runningScans}                     // necessary to initialize a customer object
                        transferList={transferList}                     // necessary to initialize a customer object
                        //  functions
                        setCust={setCust}                               // to set up current used customer
                        setOverlaySettings={setOverlaySettings}         // set up overlay config
                        setRunningScans={setRunningScans}               // current local stored scans
                        setTransferList={setTransferList}               // to update the transfer list
                    />
                }

                {   /** --- PAGE - TRANSFER LIST --- */}
                { (props.page && props.page === 'transfer-list') &&
                    <TransferList
                        //  basics
                        navigate={navigate}                             // to avoid multiple calls of this basic function
                        //  data
                        customerList={customerList}                     // necessary for search filter
                        historyList={historyList}                       // necessary to initialize a customer object
                        fullDefaultValuesList={fullDefaultValuesList}   // necessary to initialize a customer object
                        page={props.page}                               // which page is currently called
                        runningScans={runningScans}                     // necessary to initialize a customer object
                        transferList={transferList}                     // necessary to initialize a customer object
                        //  functions
                        setCust={setCust}                               // to set up current used customer
                    />
                }

                {   /** --- PAGE - TRANSFER VIEW --- */}
                { (props.page && props.page === 'transfer-view') &&
                    <TransferView
                        //  basics
                        isOnline={isOnline}                             // state of online/offline
                        isLoading={isLoading}                           // if a spinner should run
                        navigate={navigate}                             // to avoid multiple calls of this basic function
                        //  data
                        cust={cust}                                     // the full data object of current selected customer
                        customerList={customerList}                     // necessary for search filter
                        historyList={historyList}                       // necessary to initialize a customer object
                        fullDefaultValuesList={fullDefaultValuesList}   // necessary to initialize a customer object
                        page={props.page}                               // which page is currently called
                        runningScans={runningScans}                     // necessary to initialize a customer object
                        transferList={transferList}                     // necessary to initialize a customer object
                        stateProps={state}                              // holds the identifier of the scan to display
                        appUser={appUser}                               // necessary for mail to HV
                        //  functions
                        setCust={setCust}                               // to set up current used customer
                        setOverlaySettings={setOverlaySettings}         // set up overlay config
                        setRunningScans={setRunningScans}               // current local stored scans
                        setTransferList={setTransferList}               // to update the transfer list
                        setIsLoading={setIsLoading}                     // to toggle spinner
                    />
                }

                {   /** --- PAGE - INFO-PAGE --- */}
                { (props.page && props.page === 'info-page') &&
                    <InfoPage
                        appUser={appUser}                               // the app user / hv data object
                    />
                }
            </div>


            {   /** --- OVERLAY --- */}
            <div id={"overlay"} className={(overlaySettings.visible ? 'active' : '')+' '+(overlaySettings.intensity ? overlaySettings.intensity+'-intensity' : '')}>
                <Overlay
                    overlaySettings={overlaySettings}
                    setOverlaySettings={setOverlaySettings}
                />
            </div>
        </>
    );
}

export default App;
