/* --- --- --- --- ---
    customer services
   --- --- --- --- ---  */

//  import used services
import { storage_load } from './storage-service';

//  ---
//  load customer list from storage
//  ---
export function customer_loadListFromStorage() {
    let data = storage_load('customerList')
    return data || {};
}

//  ---
//  load customer default values from storage
//  ---
export function customer_loadDefaultsFromStorage() {
    let data = storage_load('fullStorageValueData')
    return data || {};
}

//  ---
//  initialize the customer object
//  ---
export function customer_init(customerKey, customerList, historyList, fullDefaultValuesList, runningScans, transferList, creator) {
    return customerKey ? {
        'creator': creator ? creator : null,
        'key': customerKey,
        'data': (customerList && customerList[customerKey]) ? customerList[customerKey] : {},
        'defaults': (fullDefaultValuesList && fullDefaultValuesList[customerKey]) ? fullDefaultValuesList[customerKey] : {},
        'history': (historyList && historyList[customerKey]) ? historyList[customerKey] : {},
        'scan': (runningScans && runningScans[customerKey]) ? runningScans[customerKey] : {},
        'transfer': (transferList && transferList[customerKey]) ? transferList[customerKey] : {},
    } : null;
}

