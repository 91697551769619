import React from 'react';

//  import components
import TransferListCard from './list-card';

//  import services
import { customer_init } from '../../../services/customer-service';

//  ---
//  Page/Component TransferList
//  ---
const TransferList = (props) => {
    //  ---
    //  return
    //  ---
    return (
        <>
            <h1>Liste zur &Uuml;bertragung</h1>

            {/** --- no data in list --- */}
            { Object.keys(props.transferList).length === 0 && (
                <p>Es stehen keine Daten zur &Uuml;bertragung an!</p>
            )}

            {/** --- list all customers in transfer list including their transfer items --- */}
            { Object.keys(props.transferList).map(customerKey => {
                let cust = customer_init(customerKey, props.customerList, props.historyList, props.fullDefaultValuesList, props.runningScans, props.transferList, '<TransferList />');
                if (Object.keys(cust.transfer).length > 0) {
                    return (
                        <TransferListCard
                            //  unique key for this element
                            key={'transfer-list-card-component-'+customerKey}
                            //  basics
                            navigate={props.navigate}                       // to avoid multiple calls of this basic function
                            //  data
                            cust={cust}
                            transferList={props.transferList}
                            page={props.page}                               // which page is currently called
                            //  functions
                            setCust={props.setCust}                         // to set up current used customer
                        />
                    );
                } else {
                    return (<></>);
                }
            })}
        </>
    );
}

export default TransferList;
