import React, { useRef, useState } from 'react';
import { FiSend } from 'react-icons/fi';

//  import services
import { scan_prepareScanItems } from '../../../services/scan-service';
import { rest_sendDefaults } from '../../../services/rest-service';

//  import components
import ScanView from '../scan/view';
import { overlay_initialize } from '../../../services/overlay-service';

//  ---
//  Page/Component CustomerSendDefaults
//  ---
const CustomerSendDefaults = (props) => {
    let myRef = useRef();

    //  ---
    //  prepare transfer-view checkbox vars
    //  ---
    const [ checks, setChecks ] = useState({
        'customer': false,
        'salesRepresentative': false
    });

    // ---
    //  get scanItemList containing just the defaults
    // ---
    let scanItemList = scan_prepareScanItems(props.cust, props.page, props.fullDefaultValuesList, false);

    //  ---
    //  send to backend
    //  ---
    function send() {
        props.setOverlaySettings(overlay_initialize);
        props.setIsLoading(true);

        rest_sendDefaults(props.appUser, {
            customer: props.cust,
            mailToCustomer: checks.customer,
            mailToSalesRepresentative: checks.salesRepresentative
        }, (response) => {
            if (true !== response) {
                //  remove spinner
                props.setIsLoading(false);
                //  display alert window
                props.setOverlaySettings({
                    visible: true,
                    type: 'alert-window',
                    headline: 'Es sind Fehler aufgetreten!',
                    messages: response,
                    intensity: 'medium',
                    actions: {
                        submit: {
                            title: 'ok',
                            callback: () => {
                                props.setOverlaySettings(overlay_initialize)
                            }
                        }
                    }
                });
            } else {
                props.setIsLoading(false);
                //  display success window
                props.setOverlaySettings({
                    visible: true,
                    type: 'alert-window',
                    headline: 'Übertragung erfolgreich',
                    messages: [
                        'Die Mail mit den Sollwerten wurde vom Backend versendet!'
                    ],
                    intensity: 'success',
                    actions: {
                        submit: {
                            title: 'ok',
                            callback: () => {
                                props.setOverlaySettings(overlay_initialize);
                                props.navigate('/customer/list');
                            }
                        }
                    }
                });
            }
        });
    }
    const askBeforeSend = () => {
        props.setOverlaySettings({
            'visible': true,
            'type': 'confirmation-window',
            'headline': 'Sicherheitsabfrage',
            'messages': [
                'Wollen Sie die Sollwerte senden?'
            ],
            'actions': {
                'submit': {
                    'title': 'ja',
                    'callback': () => { send(); }
                },
                'abort': {
                    'title': 'nein',
                    'callback': () => {
                        props.setOverlaySettings(overlay_initialize)
                    }
                }
            }
        });
    }

    // ---
    //  Toggle transfer-view checkbox changes
    // ---
    const handleCheckboxCheck = (index) => {
        setChecks({...checks, [index]: ((checks[index]) !== true)});
    }

    //  ---
    //  return
    //  ---
    return (
        <div id={"customer-send-defaults"} ref={myRef}>
            <h1>Kunden-Sollwerte senden</h1>

            <div className={"card dotted plain"}>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                Sollwerte per Mail senden an:
                                <div>
                                    <input
                                        key={'checkbox-mail-to-sales-customer'}
                                        type="checkbox"
                                        name="sendDefaultValuesMailToCustomer"
                                        id="chebkox-mail-to-customer"
                                        value={'customer'}
                                        checked={checks['customer']}
                                        onChange={() => { handleCheckboxCheck('customer'); }}
                                        disabled={props.cust.data.email.toString() === ''}
                                    />
                                    <label htmlFor="sendDefaultValuesMailToCustomer"> </label> den Kunden: { props.cust.data.email.toString() !== '' ? props.cust.data.email : '' }
                                </div>
                                <div>
                                    <input
                                        key={'checkbox-mail-to-sales-representative'}
                                        type="checkbox"
                                        name="sendDefaultValuesMailToSalesRepresentative"
                                        id="chebkox-mail-to-salesRepresentative"
                                        value={'salesRepresentative'}
                                        checked={checks['salesRepresentative']}
                                        onChange={() => { handleCheckboxCheck('salesRepresentative'); }}
                                        disabled={props.appUser.email.toString() === ''}
                                    />
                                    <label htmlFor="sendDefaultValuesMailToSalesRepresentative"> </label> an mich: { props.appUser.email }
                                </div>
                            </td>
                            <td>
                                <button
                                    className={'btn btn-primary '+(!checks['customer'] && !checks['salesRepresentative'] ? 'hidden' : '')}
                                    style={{'float':'right'}}
                                    onClick={askBeforeSend}
                                >
                                    <FiSend /> senden
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <h2>Vorschau</h2>
            <ScanView
                // data
                scanItemList={scanItemList}
                cust={props.cust}
                // settings
                useStatusStyle={false}
                printScannedValue={false}
                printDefaults={true}
                printActionButtons={false}
            />

        </div>
    );
}

export default CustomerSendDefaults;
