/* --- --- --- --- ---
    overlay service
   --- --- --- --- ---  */

//  ---
//  initialize overlay settings
//  ---
export function overlay_initialize() {
    return {
        'visible': false,
        'intensity': 'low'
    };
}

