import React from 'react';

//  import components
import ConfirmationWindow from './confirmation-window';
import AlertWindow from './alert-window';
import ScanHistoryInfoWindow from './scan-history-info-window';

//  ---
//  Component Overlay
//  ---
const Overlay = (props) => {
    //  ---
    //  initialize settings
    //  ---
    let settings = props.overlaySettings;

    //  ---
    //  return
    //  ---
    return (
        <div className={"content"}>
            { settings.type === 'confirmation-window' &&
                <ConfirmationWindow
                    settings={settings}
                    setOverlaySettings={props.setOverlaySettings}
                />
            }
            { settings.type === 'alert-window' &&
                <AlertWindow
                    settings={settings}
                    setOverlaySettings={props.setOverlaySettings}
                />
            }
            { settings.type === 'info-window' &&
                <ScanHistoryInfoWindow
                    settings={settings}
                    setOverlaySettings={props.setOverlaySettings}
                />
            }
        </div>
    );
}

export default Overlay;
