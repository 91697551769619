import React from 'react';
import { FiCheck, FiCheckCircle } from 'react-icons/fi';
import { BsExclamationTriangle } from 'react-icons/bs';

//  import components
import { date_getDateTimeString } from "../services/date-service";

//  ---
//  Component InfoWindow
//  ---
const ScanHistoryInfoWindow = (props) => {
    //  ---
    //  return
    //  ---
    return (
        <div className={'infoWindow ' + (props.settings.visibility ? 'active' : '')+' '+(props.settings.cssClassAddOn)}>
            <h2>{ props.settings.headline }</h2>
            <p>Die letzten 10 Vorg&auml;nge, absteigend sortiert.</p>
            <div>
                <table className={'innerGrid'}>
                    <thead>
                        <tr>
                            <th>Uhrzeit</th>
                            <th>EAN</th>
                            <th>Vorgang</th>
                            <th>Resultat</th>
                        </tr>
                    </thead>
                    <tbody>
                    { Object.keys(props.settings.messages).reverse().map(key => {
                        return (
                            <tr key={key}>
                                <td>{ date_getDateTimeString(key, true).substring(11) }</td>
                                <td>{ props.settings.messages[key].ean }</td>
                                <td>{
                                    (props.settings.messages[key].in === 'i'
                                        ? '+1 per Button'
                                        : (props.settings.messages[key].in === 'd'
                                            ? '-1 per Button'
                                            : (props.settings.messages[key].in === 's'
                                                ?  '+1 per Scan'
                                                : 'entfernt')))
                                }</td>
                                <td>{ props.settings.messages[key].res === 's'
                                    ? <FiCheckCircle className={"icon-success"} />
                                    : <BsExclamationTriangle className={"icon-error"} />
                                }</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td style={{'textAlign':'center','width':'100%'}}>
                                <button
                                    className={"btn btn-primary bold"}
                                    style={{'width':'140px'}}
                                    onClick={props.settings.actions.submit.callback}
                                ><FiCheck /> { props.settings.actions.submit.title}</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ScanHistoryInfoWindow;
