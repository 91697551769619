/* --- --- --- --- ---
    MISC HELPERS
   --- --- --- --- ---  */

//  ---
//  return object preformatted
//  ---
export function misc_pre(data) {
    return (
        <pre>{
            data
            ? JSON.stringify(data)
                    .replace(/[{]/g, "{\n\t")
                    .replace(/[,]/g, ",\n\t")
                    .replace(/[}]/g, "\n}\n")

                : 'not set'
        }</pre>
    );
}

//  ---
//  lpad 'string'
//  ---
export function misc_lPad(num, places, char) {
    return String(num).padStart(places, char);
}
