import React from 'react';

//  import styles
import '../../../styles/pages/customer.scss';

//  import partials
import CustomerListItem from './list-item';

//  import services
import { customer_init } from '../../../services/customer-service';

//  ---
//  Page/Component CustomerList
//  ---
const CustomerList = (props) => {

    //  ---
    //  return
    //  ---
    return (
        <div id={"customerList"}>
            <h1>Kundenliste</h1>
            { Object.keys(props.customerList).map(key => {
                return (
                    <CustomerListItem
                        //  a unique key for this element
                        key={"customer-card-"+key}

                        //  some states
                        isOnline={props.isOnline}
                        navigate={props.navigate}

                        //  data
                        cust={customer_init(key, props.customerList, props.historyList, props.fullDefaultValuesList, props.runningScans, props.transferList, '<CustomerList />')}
                        historyList={props.historyList}
                        customerList={props.customerList}
                        fullDefaultValuesList={props.fullDefaultValuesList}
                        runningScans={props.runningScans}
                        transferList={props.transferList}

                        // functions
                        loadCustomerDefaults={props.loadCustomerDefaults}
                        loadCustomersHistory={props.loadCustomersHistory}
                        setCust={props.setCust}
                    />
                );
            })}
        </div>
    );
}

export default CustomerList;
