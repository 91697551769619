/* --- --- --- --- ---
    storage service
   --- --- --- --- ---  */

//  ---
//  load and parse data from local storage as json
//  ---
export function storage_load(storageKey) {
    let storageDataObject = JSON.parse(localStorage.getItem(storageKey));
    return storageDataObject || {};
}

//  ---
//  write to local storage
//  ---
export function storage_write(storageKey, data) {
    localStorage.setItem(storageKey, JSON.stringify(data));
    return true;
}

//  ---
//  update the running scan in local storage
//  ---
export function storage_updateRunningScan(customerKey, scanData) {
    let data = storage_load('scans');
    if (!data) { data = {}; }
    data[customerKey] = scanData;
    storage_write('scans', data)
    return data;
}
