/* --- --- --- --- ---
    DATE SERVICES
   --- --- --- --- ---  */

//  import used services
import { misc_lPad } from './misc-helpers';

//  ---
//  return formatted date-time-string
//  ---
export function date_getDateTimeString(timestamp, time) {
    // 'add' milliseconds
    timestamp = parseInt(timestamp)*1000;
    // prepare usable object out of timestamp
    let thisDateTimeObject = new Date(timestamp).toLocaleString().match(/(\d+)/g);
    // get formatted date string
    //  > date
    let dateTimeString = misc_lPad(thisDateTimeObject[0], 2, '0') + '.' + misc_lPad(thisDateTimeObject[1], 2, '0') + '.' + thisDateTimeObject[2];
    //  > time (if requested)
    dateTimeString = (time === true) ? dateTimeString + ' ' + misc_lPad(thisDateTimeObject[3], 2, '0') + ':' + misc_lPad(thisDateTimeObject[4], 2, '0') : dateTimeString;
    //  return result
    return dateTimeString;
}

//  ---
//  return the current timestamp the way we use it
//  ---
export function date_timestamp() {
    return Math.floor(new Date().getTime() / 1000);
}
