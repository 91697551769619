import React, { useState } from 'react';
import { FaRegFolderOpen } from 'react-icons/fa';

//  import used services
import { date_getDateTimeString, date_timestamp } from '../../../services/date-service';

//  import styles
import 'bootstrap/dist/css/bootstrap.min.css';

//  import components
import Statistics from '../../../components/statistics';

//  ---
//  Page/Component HistoryListItem
//  ---
const HistoryListItem = (props) => {

    //  ---
    //  check if we can display an enriched statistic of this history item
    //  date of scan must match today!
    //  ---
    const [ displayEnrichedStatistics ] = useState(checkDisplayEnrichedStatistics());
    function checkDisplayEnrichedStatistics() {
        let dateTimeString = date_getDateTimeString(props.scanData.info.scanDate, false);
        let currentDateTimeString = date_getDateTimeString(date_timestamp(), false);
        return (dateTimeString === currentDateTimeString);
    }

    //  ---
    //  return
    //  ---
    return (
        <div className={"card dotted"}>
            <h2>Scan-ID: { props.scanId }</h2>
            <table>
                <tbody>
                    <tr>
                        <td style={{'width':'340px', 'paddingRight':'20px'}}>
                            <table className={'innerGrid'}>
                                <tbody>
                                    <tr>
                                        <td style={{ 'width': '150px'}}>Scan-Datum</td>
                                        <td>{ date_getDateTimeString(props.scanData.info.scanDate, true) }</td>
                                    </tr>
                                    <tr>
                                        <td style={{ 'width': '150px'}}>Transfer-Datum</td>
                                        <td>{ date_getDateTimeString(props.scanData.info.transferDate, true) }</td>
                                    </tr>
                                    <tr>
                                        <td style={{ 'width': '150px'}}>Mail an Kunde</td>
                                        <td>{ props.scanData.info.mailToCustomer ? 'ja, an: ' + props.scanData.info.mailToCustomer : 'nein' }</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td>
                            <Statistics
                                enrichedView={displayEnrichedStatistics}
                                defaults={props.cust.defaults}
                                items={props.scanData.data}
                            />
                        </td>
                        <td style={{'width':'120px', 'textAlign':'right', 'verticalAlign':'top'}}>
                            <button
                                className={"btn btn-secondary"}
                                onClick={() => {
                                    props.navigate('/history/view', {
                                        'state': {
                                            'scanId' : props.scanId,
                                            'scanData' : props.scanData,
                                            'enrichedView': displayEnrichedStatistics
                                        }
                                    })
                                }}
                            >
                                <FaRegFolderOpen /> anzeigen
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default HistoryListItem;
