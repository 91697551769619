import React from 'react';
import { FiInfo, FiCornerDownRight } from 'react-icons/fi';

//  ---
//  Component ListItem
//  ---
const ListItem = (props) => {
    //  ---
    //  return
    //  ---
    return (
        <div className={'listItem'} style={props.additionalStyle}>
            { (props.itemType && props.itemType === 'info') && <FiInfo /> }
            { (props.itemType && props.itemType === 'sub') && <FiCornerDownRight /> }
            { props.text }
        </div>
    );
}

export default ListItem;
