import React,{ useEffect } from 'react';

//  ---
//  Page/Component InfoPage
//  ---
export const InfoPage = (props) => {

    //  >> scroll to top on first page load
      useEffect(() => {
        //  >> scroll page to top on first load
        document.getElementById("content").scrollTo({ top: 0 });
    }, []);

    //  ---
    //  return
    //  ---
    return (
        <div id={"info-page"}>
            <h1>Information</h1>

            <div className={"card dotted plain"}>
                <h2>Ihre Daten</h2>
                <table className={"innerGrid"} style={{'maxWidth':'600px'}}>
                    <colgroup>
                        <col style={{'width':'200px'}} />
                        <col />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td>Login</td>
                            <td>{ props.appUser.login }</td>
                        </tr>
                        <tr>
                            <td>App-Account-Id</td>
                            <td>{ props.appUser.accountId }</td>
                        </tr>
                        <tr>
                            <td>Handelsvertreter:in</td>
                            <td>{ props.appUser.name }</td>
                        </tr>
                        <tr>
                            <td>E-Mail-Adresse</td>
                            <td>{ props.appUser.email }</td>
                        </tr>
                        <tr>
                            <td>Interne Referenz</td>
                            <td>{ props.appUser.hashref }</td>
                        </tr>
                        <tr>
                            <td>Test-Account</td>
                            <td>{ props.appUser.testAccount === "1" ? 'Ja' : 'Nein' }</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className={"card dotted plain"}>
                <h2>&Uuml;ber die App</h2>
                    <table className={"innerGrid"} style={{'maxWidth':'600px'}}>
                    <colgroup>
                        <col style={{'width':'200px'}} />
                        <col />
                    </colgroup>
                    <tbody>
                    <tr>
                        <td>Version</td>
                        <td>{ process.env.REACT_APP_VERSION_NUMBER }</td>
                    </tr>
                    <tr>
                        <td>Support-Hotline</td>
                        <td>{ process.env.REACT_APP_MEDI_HOTLINE }</td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div className={"card dotted plain"}>
                <h2>Changelog</h2>
                <p>
                    Hier finden Sie die Informationen zu den letzten &Auml;nderungen der App ab der Version 3.0.0.
                </p>

                <h3>Version 3.0.5</h3>
                    <ul>
                        <li>Fix: Refaktorisierung der Kundenadressentabelle. Die Anzeige der Adressen wurde optimiert.</li>
                        <li>Fix: Die Info Seite scrollt jetzt nach oben, wenn sie aufgerufen wird.</li>
                    </ul>

                <h3>Version 3.0.3</h3>
                    <ul>
                        <li>Fix: Entfernung der Anzeige des Datums der letzten Lieferung in allen Darstellungen.</li>
                    </ul>

                <h3>Version 3.0.2</h3>
                    <ul>
                        <li>Fix: Erhöhung des Timeouts bei der Übertragung von Scans großer Lager auf 30 Sekunden.</li>
                        <li>Fix: Fehlerbehebung beim Aufruf der Passwort-Reset-Seite.</li>
                        <li>Fix: Anpassungen bzgl. Suchmaschinen.</li>
                    </ul>

                <h3>Version 3.0.1</h3>
                    <ul>
                        <li>
                            Technisch <b>komplett neue Version</b>, jedoch ohne &Auml;nderung an der Datenstruktur.<br />
                            Ein Umstieg von der Version 2.5.2 (letzte V2.x-Version) ist im laufenden Betrieb m&ouml;glich.<br />
                            Kein Wegfall bekannter Funktionen.
                        </li>
                        <li>
                            <b>Komplett &uuml;berarbeites Men&uuml;:</b><br />
                            Der aktuelle ausgew&auml;hlte Kunde erscheint immer im sichtbaren Kopfbereich der App, anstatt im Men&uuml;
                        </li>
                        <li>
                            <b>Komplett &uuml;berarbeite Kundenliste:</b><br />
                            Alle zur Verf&uuml;gung stehenden Aktionen zu einem Kunden sind aus der Kundenliste heraus ausf&uuml;hrbar.
                        </li>
                        <li>
                            <b>Statistiken:</b><br />
                            In den Auflistungen der gescannten Artikel (EAN Barcode-Scan) werden im unteren Bereich,
                            ebenso wie in manchen Listendarstellungen, die Menge an unterschiedlicen gescannten EAN-Barcodes,
                            sowie die gescannten Einheiten dargestellt. <br />
                            Beispiel: es wurden 4 Mal Kartons des "mediven comfort CCL2 AD cashmere m Sp" in Gr&ouml;&szlig;e II gescannt,
                            so ergibt das einen unterschiedlichen EAN mit 4 Einheiten. <br />
                            Dies steigert die &Uuml;bersichtlichkeit der Scans und soll fehlerhafte, durch Scan-Vorg&auml;nge ausgel&ouml;ste
                            Bestellungen vermeiden. <br />
                            Sind die Sollwerte des Kunden geladen und macht die Darstellung des Vergleichs in der aktuellen Ansicht Sinn,
                            so wird Ihnen die Sollmenge gegen&uuml;bergestellt und eine prozentuale Deckung ausgerechnet.
                        </li>
                        <li>
                            <b>FAQ - H&auml;fig gestellte Fragen:</b><br />
                            Die Inhalte wurden auf die neue Version hin angepasst.
                        </li>
                        <li>
                            <b>Ladebuttons:</b><br />
                            Je nach dargestelltem Inhalt werden im Kopfbereich der App die f&uuml;r diese Ansicht sinnvolle Aktion per Button angeboten.
                            Z.B.: Sollwerte neu laden.
                        </li>
                        <li>
                            <b>Scan-Formular:</b><br />
                            Solange Sie sich innerhalb des Scan-Formulars aufhalten werden alle Aktionen in einer Art Logfile
                            abgelegt. Sie können dieses Log &uuml;ber den Verlauf-Button aufrufen. Es werden die letzten 10 Eintr&auml;ge dargestellt.<br />
                            Dies hilft Ihnen ggf. im Fehlerfall nachvollziehen zu k&ouml;nnen, was bis zum Fehler bereits verarbeitet wurde, sodass Sie
                            den Scan nicht unbedingt verwerfen und nochmals von vorne anfangen m&uuml;ssen.
                        </li>
                    </ul>
            </div>

        </div>
    );
}

export default InfoPage;
