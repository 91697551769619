import React from 'react';

//  import components
import CustomerAddress from '../customer/address';
import TransferListCardItem from './list-card-item';

//  ---
//  Page/Component TransferListCard
//  ---
const TransferListCard = (props) => {
    //  ---
    //  return
    //  ---
    return (
        <div
            key={"transfer-list-item-"+props.cust.key}
            className={"card dotted "}
        >
            <table>
                <tbody>
                    <tr>
                        <td style={{'width':'50%', 'verticalAlign':'top'}}>
                            <CustomerAddress
                                // unique key
                                key={'customer-'+props.cust.key}
                                //  data
                                cust={props.cust}
                                //  settings
                                showAddress={true}
                                showCustomerNumber={true}
                                showCustomerEMail={true}
                                additionalStyle={'customerListItem'}
                            />
                        </td>
                        <td style={{'width':'50%', 'verticalAlign':'top'}}>
                         { Object.keys(props.transferList[props.cust.key]).map(transferIdent => {
                             return (
                                 <TransferListCardItem
                                     // unique key
                                     key={"transfer-list-card-item-"+props.cust.key+'-'+transferIdent}
                                     // basics
                                     navigate={props.navigate}                                  // to avoid multiple calls of this basic function
                                     // data
                                     cust={props.cust}                                          //  the full customer object
                                     transferIdent={transferIdent}                              //  ident of this transfer object
                                     data={props.transferList[props.cust.key][transferIdent]}   //  the data of this transfer object
                                     page={props.page}                                          // which page is currently called
                                     // functions
                                     setCust={props.setCust}                                    // to set up current used customer
                                 />
                             );
                         })}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default TransferListCard;
