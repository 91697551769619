import React from 'react';

//  import styles
import '../../../styles/pages/customer.scss';

//  ---
//  Page-component CustomerAddress
//  ---
const CustomerAddress = (props) => {

    //  ---
    //  return
    //  ---
    return (
        <div className={"customerAddress "+(props.additionalStyle ? props.additionalStyle : '')}>
            <h2>{ (props.cust.data.companyName + ' ' + props.cust.data.firstName + ' ' +props.cust.data.lastName).trim() }</h2>
            { props.showAddress && (
                <p>
                    {props.cust.data.street}<br />
                    {props.cust.data.postcode} {props.cust.data.city}<br />
                </p>
            )}
            <p>
                { props.showCustomerNumber && (<>KdNr {props.cust.key}<br /></>)}
                { props.showCustomerEMail && (props.cust.data.email)}
            </p>
        </div>
    );
}

export default CustomerAddress;
