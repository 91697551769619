import React, { useState, useRef, useEffect } from 'react';

//  import styles
import '../../../styles/pages/scan.scss';

import { FiArrowRightCircle, FiXCircle, FiInfo } from 'react-icons/fi';

import { BsClockHistory } from 'react-icons/bs';

//  import services
import {scan_validateEan, scan_prepareScanItems, scan_getCssClass} from '../../../services/scan-service';
import { storage_updateRunningScan, storage_write, storage_load } from '../../../services/storage-service';
import { overlay_initialize } from '../../../services/overlay-service';
import { date_timestamp } from '../../../services/date-service';

//  import components
import Ean from './ean';

//  ---
//  Page/Component ScanForm
//  ---
const ScanForm = (props) => {
    const searchInputRef = useRef();

    //  ---
    //  ean input field
    //  ---
    const [ eanInput, setEanInput ] = useState('');

    //  ---
    //  ean action log
    //  ---
    const [ eanLog, setEanLog ] = useState({});

    //  ---
    //  medi ean number range
    //  ---
    const mediEanRange = JSON.parse(process.env.REACT_APP_MEDI_EAN_RANGE);
    const log = (data) => {
        // current timestamp
        let currentTimestamp = date_timestamp();
        // initialize customer
        if (!eanLog || !eanLog[props.cust.key]) {
            eanLog[props.cust.key] = {};
        }
        // add to log
        eanLog[props.cust.key][currentTimestamp] = data;
        // shrink to latest max. 10 entries
        let newEanLog = {};
        let oldEanLogKeys = Object.keys(eanLog[props.cust.key]).reverse();
        for (let x=0; x<Object.keys(eanLog[props.cust.key]).length; x++) {
            if (Object.keys(newEanLog).length < 10) {
                newEanLog[oldEanLogKeys[x].toString()] = eanLog[props.cust.key][oldEanLogKeys[x]];
            }
        }
        // update var
        eanLog[props.cust.key] = newEanLog;
        setEanLog(eanLog);
    }
    const showLog = () => {
        props.setOverlaySettings({
            visible: true,
            type: 'info-window',
            headline: 'Verlauf des Scan-Vorgangs',
            messages:  eanLog[props.cust.key],
            intensity: 'info',
            actions: {
                submit: {
                    title: 'ok',
                    callback: () => {
                        props.setOverlaySettings(overlay_initialize)
                        searchInputRef.current.focus();
                    }
                }
            }
        });
    }

    //  ---
    //  prepare current scan item list
    //  ---
    const initializeScanItemList = () => {
        return scan_prepareScanItems(props.cust, props.page, props.fullDefaultValuesList, false);
    }
    const [ scanItemList, setScanItemList ] = useState(initializeScanItemList);

    //  ---
    //  form functions
    //  ---
    const changeScanQuantity = (e) => {
        e.preventDefault();
        // get ean from input field
        let ean = eanInput.trim();
        // only perform this input as long as the input has any value
        if (ean !== '') {
            // run ean validation
            let eanCheck = scan_validateEan(ean, mediEanRange);
            if (true !== eanCheck) {
                // add to log
                log({ 'ean': ean, 'in': 's', 'res': 'e'});
                // display alert window
                props.setOverlaySettings({
                    visible: true,
                    type: 'alert-window',
                    headline: 'Es sind Fehler aufgetreten!',
                    messages: eanCheck,
                    intensity: 'max',
                    actions: {
                        submit: {
                            title: 'ok',
                            callback: () => {
                                props.setOverlaySettings(overlay_initialize)
                                searchInputRef.current.focus();
                            }
                        }
                    }
                });
            } else {
                // increase quantity
                increaseScanQuantity(ean, 's');
            }
        setEanInput('');
        }
    }
    const increaseScanQuantity = (ean, inputType) => {
        // add to log
        log({ 'ean': ean, 'in': inputType, 'res': 's'});
        //  increase quantity
        props.cust.scan[ean] = (!props.cust.scan[ean]) ? 1 : props.cust.scan[ean]+1;
        //  handle quantity update
        handleQuantityChange(props.cust.scan);
        //  return new quantity
        return props.cust.scan[ean];
    }
    const decreaseScanQuantity = (ean) => {
        // add to log
        log({ 'ean': ean, 'in': 'd', 'res': 's'});
        //  increase quantity
        props.cust.scan[ean] = (!props.cust.scan[ean]) ? 0 : props.cust.scan[ean]-1;
        //  avoid quantities lower zero
        props.cust.scan[ean] = (props.cust.scan[ean] < 0) ? 0 : props.cust.scan[ean];
        //  handle quantity update
        handleQuantityChange(props.cust.scan);
        //  return new quantity
        return props.cust.scan[ean];
    }
    const removeEanFromScan = (ean) => {
        // add to log
        log({ 'ean': ean, 'in': 'r', 'res': 's'});
        //  delete from current scan
        let scanData = {...props.cust.scan};
        if (scanData[ean]) {
            delete scanData[ean];
        }
        props.cust.scan={...scanData};
        //  handle change
        handleQuantityChange(scanData);
    }
    const handleQuantityChange = (scanData) => {
        //  write down to local storage and re-init 'global' var
        storage_updateRunningScan(props.cust.key, scanData);
        //  update 'global' var
        props.setRunningScans(storage_load('scans'));
    }

    //  ---
    //  calculate amount of items in this scan
    //  ---
    const [ unitCount, setUnitCount ] = useState(calculateUnitCount);
    function calculateUnitCount() {
        let count = 0;
        let eanObjectKeys = Object.keys(scanItemList);
        for (let x=0; x<eanObjectKeys.length; x++) {
            if (scanItemList[eanObjectKeys[x]].currentQuantity) {
                count = count + scanItemList[eanObjectKeys[x]].currentQuantity;
            }
        }
        return count;
    }

    //  ---
    //  discard this scan
    //  ---
    function discard() {
        //  reset and hide overlay
        props.setOverlaySettings(overlay_initialize);
        let runningScans = storage_load('scans');
        if (runningScans[props.cust.key]) {
            delete runningScans[props.cust.key];
        }
        runningScans={...runningScans};
        storage_write('scans', runningScans);
        props.setRunningScans(storage_load('scans'));
        //  update customer
        props.setCust({...props.cust, scan: {}});
        //  navigate to customer list
        props.navigate('/customer/list');
    }
    const askForDiscard = () => {
        props.setOverlaySettings({
            'visible': true,
            'type': 'confirmation-window',
            'headline': 'Sicherheitsabfrage',
            'messages': [
                'Wollen Sie diesen Scan wirklich komplett verwerfen?'
            ],
            'actions': {
                'submit': {
                    'title': 'verwerfen',
                    'callback': () => { discard(); }
                },
                'abort': {
                    'title': 'nein',
                    'callback': () => {
                        props.setOverlaySettings(overlay_initialize)
                    }
                }
            }
        });
    }

    //  ---
    //  some effects
    //  ---
    //  >> scroll to top on first page load
    useEffect(() => {
        //  >> scroll page to top on first load
        document.getElementById("content").scrollTo({ behavior: 'smooth', top: 0 });
        //  >> focus on search input at page load
        searchInputRef.current.focus();
    }, []);

    useEffect(() => {
        //  >> set up scanItemList new after customer object has changed
        setScanItemList(initializeScanItemList);
        //  >> calculate units in scan to hide / display buttons
        setUnitCount(calculateUnitCount);
        // eslint-disable-next-line
    }, [ props.cust ]);

    useEffect(() => {
        //  >> set up scanItemList new after changes for running scans
        setScanItemList(initializeScanItemList);
        //  >> calculate units in scan to hide / display buttons
        setUnitCount(calculateUnitCount);
        // eslint-disable-next-line
    }, [ props.runningScans ]);

    //  >> scroll to ean that matches the input field
    useEffect(() => {
        if (eanInput !== '') {
            function startsWith(array, eanInput) {
                const matcher = new RegExp(`^${eanInput}`, 'g');
                return array.filter(ean => ean.match(matcher));
              }
            const array = Object.keys(scanItemList)
            const result = startsWith(array, eanInput)
            try {
                document.getElementById("content").scrollTo({ top: document.getElementById(result[0]).offsetTop - 130 });
            } catch (error) {
                console.log(error)
            }
        }
    }, [eanInput,scanItemList])
    

    //  ---
    //  return
    //  ---
    return (
        <>
        <div className='scan-form'>
            <div className='scan-form-header'>
                { (eanLog && eanLog[props.cust.key]) && (
                    <span style={{'float':'right'}}>
                        <button
                            className={'btn btn-secondary'}
                            onClick={showLog}
                        >
                            <BsClockHistory /> Verlauf
                        </button>
                    </span>
                )}

                <div style={{'justifyContent': 'space-between', 'display':'flex', 'marginBottom':'10px'}}>
                    <h1 >Lagerbestand erfassen</h1>
                    {
                        props.fullDefaultValuesList[props.cust.key] !== undefined && Object.keys(props.cust.defaults).length < 1 ? 
                    <p
                        style={{color: "red"}}> Keine Sollwerte verfügbar <FiInfo/> 
                    </p> 
                    : ''
                    }
                </div>

                <div>
                    <form onSubmit={changeScanQuantity}>
                        <input
                            ref={searchInputRef}
                            className="input-text ean-input"
                            name="eanInput"
                            placeholder="Artikelnummer scannen"
                            onChange={event => setEanInput(event.target.value)}
                            value={eanInput}
                        />
                    </form>
                </div>
            </div>
            <div className='scan-form-body'>
                { Object.keys(scanItemList).map(ean => {
                    return (
                        <div id={ean}>
                            <Ean
                                // provide unique key for this element
                                key={'scan-form-ean-'+ean}

                                // states
                                printScannedValue={true}                        //  show the amount of scanned ean
                                printDefaults={true}                            //  display the default-values information?
                                printLatestDeliveryDate={true}                  //  display latest delivery date?
                                printActionButtons={true}                       //  print + - x buttons?
                                useStatusStyle={true}                           //  use stockCssClass

                                // data we need
                                eanQuantity={scanItemList[ean].currentQuantity} //  to trigger quantity-updates by form input
                                ean={scanItemList[ean]}                         //  the full ean data object to render
                                cust={props.cust}                               //  the current used customer
                                page={props.page}                               //  the requested page
                                scanItemList={scanItemList}                     //  to trigger re-rendering
                                currentEanCssClass={scan_getCssClass(props.cust, scanItemList[ean], scanItemList[ean].currentQuantity)}

                                //  functions
                                increaseScanQuantity={increaseScanQuantity}
                                decreaseScanQuantity={decreaseScanQuantity}
                                removeEanFromScan={removeEanFromScan}
                                setOverlaySettings={props.setOverlaySettings}
                            />
                        </div>
                    );
                })}

                <div className={"buttonBar "+( unitCount === 0 ? 'hidden' : '')}>
                    <button
                        className={"btn btn-primary bold"}
                        onClick={() => {
                            props.navigate('/scan/preview')
                        }}
                    >
                        <FiArrowRightCircle /> weiter
                    </button>
                    <button
                        className={"btn btn-warning bold"}
                        onClick={askForDiscard}
                    >
                        <FiXCircle /> verwerfen
                    </button>
                </div>
            </div>
        </div>
        </>
    );
}

export default ScanForm;
