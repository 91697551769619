import React from 'react';
import { FiArrowLeftCircle } from 'react-icons/fi';

//  import components
import ScanView from './../scan/view';
import Statistics from '../../../components/statistics';

//  import services
import { date_getDateTimeString, date_timestamp } from '../../../services/date-service';
import { scan_prepareScanItems } from '../../../services/scan-service';

//  ---
//  Page/Component HistoryLatest
//  ---
const HistoryLatest = (props) => {
    //  ---
    //  get: latest scan identifier in history
    //  ---
    const latestHistoryId = getLatestHistoryId();
    function getLatestHistoryId() {
        let max = 0;
        let keys = Object.keys(props.cust.history);
        for (let x=0; x<keys.length; x++) {
            let currentCheckValue = max < 0 ? max*-1 : max;
            let currentCheckAgainst = keys[x] < 0 ? keys[x]*-1 : keys[x];
            max = currentCheckAgainst > currentCheckValue ? keys[x] : max;
        }
        return max !== 0 ? max : false;
    }

    //  ---
    //  get scanItemList for latest historic scan
    //  ---
    let scanItemList = scan_prepareScanItems(props.cust, props.page, props.fullDefaultValuesList, latestHistoryId);

    //  ---
    //  return
    //  ---
    return (
        <>
            <h1>Letzte &Uuml;bertragung f&uuml;r diesen Kunden: ScanID {latestHistoryId}</h1>
            <div className={'card dotted plain'}>
                <h2>Info</h2>
                <table className={'innerGrid'}>
                    <tbody>
                    <tr>
                        <td style={{ 'width': '150px'}}>Scan-Datum</td>
                        <td>{ date_getDateTimeString(props.cust.history[latestHistoryId].info.scanDate, true) }</td>
                    </tr>
                    <tr>
                        <td style={{ 'width': '150px'}}>Transfer-Datum</td>
                        <td>{ date_getDateTimeString(props.cust.history[latestHistoryId].info.transferDate, true) }</td>
                    </tr>
                    <tr>
                        <td style={{ 'width': '150px'}}>Mail an Kunde</td>
                        <td>{ props.cust.history[latestHistoryId].info.mailToCustomer ? 'ja, an: ' + props.cust.history[latestHistoryId].info.mailToCustomer : 'nein' }</td>
                    </tr>
                    </tbody>
                </table>

            </div>
            <ScanView
                // data
                scanItemList={scanItemList}
                cust={props.cust}
                // settings
                useStatusStyle={false}
                printScannedValue={true}
                printDefaults={false}
                printActionButtons={false}
            />
            <div className={'card dotted plain'}>
                <h2>Statistik</h2>
                <Statistics
                    enrichedView={date_getDateTimeString(date_timestamp(), false) === props.cust.history[latestHistoryId].info.scanDate}
                    defaults={props.cust.defaults}
                    items={props.cust.history[latestHistoryId].data}
                />
            </div>
            <div>
                <button
                    className={'btn btn-primary bold'}
                    onClick={() => {
                        props.navigate('/customer/list')
                    }}
                >
                    <FiArrowLeftCircle /> zur&uuml;ck
                </button>
            </div>
        </>
    );
}

export default HistoryLatest;
