import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom"

//  styles
import './styles/index.scss';

//  content > restricted area
import App from './pages/restricted/app';

//  content > 'public' area
import Login from './pages/public/login';
import Logout from './pages/public/logout';
import FourOFourPage from './pages/public/404';
import PasswordRequest from './pages/public/password-request';
import PasswordReset from './pages/public/password-reset';

//  service worker
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

//  ---
//  Render app
//  ---
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<App page="customer-list" />} />

                {/* --- LOGIN / LOGOUT --- */}
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/password/request-reset" element={<PasswordRequest />} />
                <Route path="/password/set" element={<PasswordReset />} />

                {/* --- CUSTOMER --- */}
                <Route path="/customer/list" element={<App page="customer-list" />} />
                <Route path="/customer/send-defaults" element={<App page="customer-send-defaults" />} />

                {/* --- FAQ --- */}
                <Route path="/faq" element={<App page="faq" />} />

                {/* --- HISTORY --- */}
                <Route path="/history/list" element={<App page="history-list" />} />
                <Route path="/history/view" element={<App page="history-view" />} />
                <Route path="/history/latest" element={<App page="history-latest" />} />

                {/* --- SCAN --- */}
                <Route path="/scan/form" element={<App page="scan-form" />} />
                <Route path="/scan/preview" element={<App page="scan-preview" />} />

                {/* --- TRANSFER --- */}
                <Route path="/transfer/list" element={<App page="transfer-list" />} />
                <Route path="/transfer/view" element={<App page="transfer-view" />} />

                {/*/!* --- MISC --- *!/*/}
                <Route path="/info" element={<App page="info-page" />} />
                <Route path="*" element={<FourOFourPage />} />
            </Routes>
        </BrowserRouter>
    </>
);

// register service worker
serviceWorkerRegistration.register();
