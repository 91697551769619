import React from 'react';
import {Link } from 'react-router-dom';
import { FiMenu, FiUsers, FiLogOut, FiHelpCircle, FiUploadCloud, FiInfo } from 'react-icons/fi';

//  import menu styles
import '../styles/components/menu.scss';

//  import services
import { overlay_initialize } from '../services/overlay-service';

//  ---
//  Component Menu
//  ---
const Menu = (props) => {
    //  ---
    //  view / hide menu
    //  ---
    const toggleMenu = () => {
        props.setMenuStatus(!props.menuStatus);
    }
    const closeMenu = () => {
        props.setMenuStatus(false);
    }

    //  ---
    //  logout
    //  ---
    const logout = () => {
        // clear complete storage
        localStorage.clear();
        // navigate to login page
        props.navigate('/login');
    }

    //  ---
    //  confirmation window
    //  ---
    const displayConfirmationWindowForLogout = () => {
        props.setOverlaySettings({
            'visible': true,
            'type': 'confirmation-window',
            'headline': 'Sicherheitsabfrage',
            'messages': [
                'Wollen Sie sich wirklich von der ConsiApp abmelden?',
                'Alle gespeicherten Werte würden dadurch gelöscht werden!'
            ],
            'actions': {
                'submit': {
                    'title': 'ja, abmelden',
                    'callback': () => { logout(); }
                },
                'abort': {
                    'title': 'nein',
                    'callback': () => {
                        props.setOverlaySettings(overlay_initialize)
                    }
                }
            }
        });
    }

    //  ---
    //  return
    //  ---
    return (
        <div id={"menu"} className={(props.menuStatus ? 'active' : '')+'' +(props.isLoading ? 'frozen' : '')}>

            <div className={"header"+(props.menuStatus ? ' active' : '')}>
                <span className="logoBox">
                    <img src="/images/medi-logo.192.png" title="ConsiApp" alt="ConsiApp" />
                </span>
                <span className="toggleBox">
                    <button onClick={toggleMenu} className={'menuToggleButton ' + (props.menuStatus ? ' active' : '')} ><FiMenu /></button>
                </span>
            </div>

            <div className={"body"+(props.menuStatus ? ' active' : '')}>
                <ul>
                    <Link to="/customer/list" onClick={closeMenu}>
                        <li>
                            <FiUsers className="itemIcon" />
                            Kundenliste
                        </li>
                    </Link>
                    <Link to="/transfer/list" onClick={closeMenu}>
                        <li>
                            <FiUploadCloud className="itemIcon" />
                            Liste zur &Uuml;bertragung
                        </li>
                    </Link>
                    <Link to="/faq" onClick={closeMenu}>
                        <li>
                            <FiHelpCircle className="itemIcon" />
                            H&auml;ufig gestellte Fragen
                        </li>
                    </Link>
                    <Link to="/info" onClick={closeMenu}>
                        <li>
                            <FiInfo className="itemIcon" />
                            Info
                        </li>
                    </Link>
                    <li onClick={displayConfirmationWindowForLogout}>
                        <FiLogOut className="itemIcon" />
                        Abmelden
                    </li>
                </ul>

                <p style={{'textAlign':'center','marginTop':'30px'}}>
                    Hotline:<br />
                    {process.env.REACT_APP_MEDI_HOTLINE}
                </p>
            </div>

        </div>
    );
}

export default Menu;
