import React from 'react';

// import styles
import '../../styles/pages/public.scss';

//  ---
//  Page/Component 404
//  ---
const FourOFourPage = () => {
    return (
        <div id="fourOfourPage">
            <div>
                <p><img src="/images/medi-logo.192.png" alt="medi ConsiApp" /></p>
            </div>
            <div>
                <p>Sie haben einen ung&uuml;ltigen Aufruf gestartet!</p>
                <p>Bitte klicken Sie <a href="/">hier</a> um zur&uuml;ck zur Startseite zu gelangen.</p>
            </div>
        </div>
    );
}

export default FourOFourPage;
