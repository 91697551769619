import React, { useState, useEffect, useRef } from 'react';
import { FiSend, FiEdit, FiXCircle, FiList } from 'react-icons/fi';

//  import components
import ScanView from '../scan/view';
import Statistics from '../../../components/statistics';

//  import services
import { date_getDateTimeString } from '../../../services/date-service';
import { overlay_initialize } from '../../../services/overlay-service';
import { storage_load, storage_updateRunningScan, storage_write } from '../../../services/storage-service';
import { scan_prepareScanItems } from '../../../services/scan-service';
import { rest_sendScanToBackend} from '../../../services/rest-service';

//  ---
//  Page/Component TransferView
//  ---
const TransferView = (props) => {
    let myRef = useRef();

    //  ---
    //  prepare transfer-view checkbox vars
    //  ---
    const [ checks, setChecks ] = useState({
        'customer': false,
        'salesRepresentative': false
    });

    //  ---
    //  get scanItemList for scan in transfer list
    //  ---
    let scanItemList = scan_prepareScanItems(props.cust, props.page, props.fullDefaultValuesList, props.stateProps.transferIdent);

    //  ---
    //  can we display enriched data
    //  ---
    const [ displayEnriched, setDisplayEnriched ] = useState(hasEnrichedData);
    function hasEnrichedData() {
        return (Object.keys(props.cust.defaults).length > 0);
    }

    //  ---
    //  check for running scan
    //  ---
    const runningScan = (Object.keys(props.cust.scan).length > 0); // true | false

    //  ---
    //  remove this scan from transfer list
    //  called by discard() or edit()
    //  ---
    function remove() {
        // reset and hide overlay
        props.setOverlaySettings(overlay_initialize);
        // remove scan from transfer list
        let transferList = storage_load('transferList');
        if (transferList[props.cust.key][props.stateProps.transferIdent]) {
            delete transferList[props.cust.key][props.stateProps.transferIdent];
        }
        // remove customer completely from storage if no other scans are in transfer list
        if (Object.keys(transferList[props.cust.key]).length === 0) {
            delete transferList[props.cust.key];
        }
        transferList={...transferList};
        storage_write('transferList', transferList);
        props.setTransferList(storage_load('transferList'));
        //  update customer
        let customersTransferList = (transferList[props.cust.key]) ? {...transferList[props.cust.key]} : {};
        props.setCust({...props.cust, transfer: customersTransferList});
    }

    //  ---
    //  discard this scan
    //  ---
    function discard() {
        remove();
        // navigate to transfer list
        props.navigate('/transfer/list');
    }
    const askForDiscard = () => {
        props.setOverlaySettings({
            'visible': true,
            'type': 'confirmation-window',
            'headline': 'Sicherheitsabfrage',
            'messages': [
                'Wollen Sie diesen Scan wirklich komplett verwerfen?'
            ],
            'actions': {
                'submit': {
                    'title': 'verwerfen',
                    'callback': () => { discard(); }
                },
                'abort': {
                    'title': 'nein',
                    'callback': () => {
                        props.setOverlaySettings(overlay_initialize)
                    }
                }
            }
        });
    }

    //  ---
    //  return to edit-mode
    //  ---
    function edit() {
        //  replace running scan
        storage_updateRunningScan(props.cust.key, props.cust.transfer[props.stateProps.transferIdent]);
        //  update 'global' var
        props.setRunningScans(storage_load('scans'));
        //  remove this scan from transfer list
        remove();
        // navigate to scan form
        props.navigate('/scan/form');
    }
    const askForEditTakeOver = () => {
        props.setOverlaySettings({
            'visible': true,
            'type': 'confirmation-window',
            'headline': 'Sicherheitsabfrage',
            'messages': [
                'Es liegt noch ein nicht gespeicherter Scan für diesen Kunden vor!',
                'Wenn Sie diesen Scan zur Bearbeitung öffnen wird dieser hiermit verworfen!'
            ],
            'actions': {
                'submit': {
                    'title': 'ja',
                    'callback': () => { edit(); }
                },
                'abort': {
                    'title': 'nein',
                    'callback': () => {
                        props.setOverlaySettings(overlay_initialize)
                    }
                }
            }
        });
    }

    //  ---
    //  send to backend
    //  ---
    function sendToBackend() {
        props.setOverlaySettings(overlay_initialize);
        props.setIsLoading(true);

        rest_sendScanToBackend(props.appUser, {
            mailToCustomer: checks.customer,
            mailToSalesRepresentative: checks.salesRepresentative,
            appVersion: process.env.REACT_APP_VERSION_NUMBER,
            timeStamp: props.stateProps.transferIdent,
            customer: props.cust,
            scanData: props.cust.transfer[props.stateProps.transferIdent]
        }, (response) => {
            if (true !== response) {
                //  remove spinner
                props.setIsLoading(false);
                //  display alert window
                props.setOverlaySettings({
                    visible: true,
                    type: 'alert-window',
                    headline: 'Es sind Fehler aufgetreten!',
                    messages: response,
                    intensity: 'medium',
                    actions: {
                        submit: {
                            title: 'ok',
                            callback: () => {
                                props.setOverlaySettings(overlay_initialize)
                            }
                        }
                    }
                });
            } else {
                props.setIsLoading(false);
                //  display success window
                props.setOverlaySettings({
                    visible: true,
                    type: 'alert-window',
                    headline: 'Übertragung erfolgreich',
                    messages: [
                        'Der Scan wurde erfolgreich übertragen!',
                        'Um diese Übertragung in der Historie zu sehen, laden Sie bitte die Historie des Kunden über die Kundenliste neu.'
                    ],
                    intensity: 'success',
                    actions: {
                        submit: {
                            title: 'ok',
                            callback: () => {
                                props.setOverlaySettings(overlay_initialize);
                                remove();
                                props.navigate('/customer/list');
                            }
                        }
                    }
                });
            }
        });
    }
    const askBeforeSendToBackend = () => {
        props.setOverlaySettings({
            'visible': true,
            'type': 'confirmation-window',
            'headline': 'Sicherheitsabfrage',
            'messages': [
                'Wollen Sie den Scan jetzt übertragen?'
            ],
            'actions': {
                'submit': {
                    'title': 'ja',
                    'callback': () => { sendToBackend(); }
                },
                'abort': {
                    'title': 'nein',
                    'callback': () => {
                        props.setOverlaySettings(overlay_initialize)
                    }
                }
            }
        });
    }

    // ---
    // Toggle transfer-view checkbox changes
    // ---
    const handleCheckboxCheck = (index) => {
        setChecks({...checks, [index]: ((checks[index]) !== true)});
    }

    //  ---
    //  some effect
    //  ---
    useEffect(() => {
        setDisplayEnriched(hasEnrichedData);
        // eslint-disable-next-line
    }, [ props.cust ]);

    //  ---
    //  return
    //  ---
    return (
        <>
            <h1>Voransicht des noch zu &uuml;bertragenden Scans</h1>

            <div className={'card dotted plain'} ref={myRef}>
                <h2>Info</h2>
                <table className={'innerGrid'}>
                    <tbody>
                    <tr>
                        <td style={{ 'width': '150px'}}>Scan-Datum</td>
                        <td>{ date_getDateTimeString(props.stateProps.transferIdent, true) }</td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <ScanView
                // data
                scanItemList={scanItemList}
                cust={props.cust}
                // settings
                useStatusStyle={true}
                printScannedValue={true}
                printDefaults={true}
                printActionButtons={false}
            />

            <div className={'card dotted plain'}>
                <h2>Statistik</h2>
                <Statistics
                    enrichedView={displayEnriched}
                    defaults={props.cust.defaults}
                    items={props.cust.transfer[props.stateProps.transferIdent]}
                />
            </div>

            <div className={'card dotted plain'}>
                Scan-Auswertung nach der Übermittlung per Mail an:
                <div>
                    <input
                        key={'checkbox-mail-to-sales-customer'}
                        type="checkbox"
                        name="sendTransferInfoMailToCustomer"
                        id="chebkox-mail-to-customer"
                        value={'customer'}
                        checked={checks['customer']}
                        onChange={() => { handleCheckboxCheck('customer'); }}
                    />
                    <label htmlFor="sendTransferInfoMailToCustomer"> </label> den Kunden senden { props.cust.data.email.toString() !== '' ? '('+props.cust.data.email+')' : '' }
                </div>
                <div>
                    <input
                        key={'checkbox-mail-to-sales-representative'}
                        type="checkbox"
                        name="sendTransferInfoMailToSalesRepresentative"
                        id="chebkox-mail-to-salesRepresentative"
                        value={'salesRepresentative'}
                        checked={checks['salesRepresentative']}
                        onChange={() => { handleCheckboxCheck('salesRepresentative'); }}
                    />
                    <label htmlFor="sendTransferInfoMailToSalesRepresentative"> </label> an mich senden ({ props.appUser.email })
                </div>
            </div>

            <div className={"buttonBar"}>
                <button
                    className={"btn btn-primary bold"+( !props.isOnline || props.isLoading ? ' hidden' : '' )}
                    onClick={askBeforeSendToBackend}
                >
                    <FiSend /> &uuml;bertragen
                </button>
                <button
                    className={"btn btn-secondary bold"}
                    onClick={() => {
                        if (runningScan) {
                            askForEditTakeOver();
                        } else {
                            edit();
                        }
                    }}
                >
                    <FiEdit /> bearbeiten
                </button>
                <button
                    className={"btn btn-secondary bold"}
                    onClick={() => {
                        props.navigate('/transfer/list');
                    }}
                >
                    <FiList /> zur Liste
                </button>
                <button
                    className={"btn btn-warning bold"}
                    onClick={askForDiscard}
                >
                    <FiXCircle /> verwerfen
                </button>
            </div>

        </>
    );
}

export default TransferView;
