import React from 'react';

//  import components
import HistoryListItem from './list-item';

//  ---
//  Page/Component HistoryList
//  ---
const HistoryList = (props) => {
    //  ---
    //  sort history by date desc
    //  ---
    let sortedHistoryKeys = getSortedHistoryKeys();
    function getSortedHistoryKeys() {
        // prepare data
        let timestampToScanId = {};
        let timestampList = [];
        let sortKeys = Object.keys(props.cust.history);
        for (let x = 0; x < sortKeys.length; x++) {
            timestampToScanId[props.cust.history[sortKeys[x]].info.scanDate] = sortKeys[x];
            timestampList.push(props.cust.history[sortKeys[x]].info.scanDate);
        }
        // sort it "normal"
        let ascSortedTimestamps = timestampList.sort();
        // sort it descending
        let descSortedTimestamps = [];
        for (let x=(ascSortedTimestamps.length-1); x>=0; x--) {
            descSortedTimestamps.push(timestampToScanId[ascSortedTimestamps[x]]);
        }
        return descSortedTimestamps;
    }

    //  ---
    //  return
    //  ---
    return (
        <>
            <h1>
                Historie dieses Kunden
                { !props.isLoading && props.cust.history && Object.keys(props.cust.history).length > 0 && (
                        <> - die letzten { Object.keys(props.cust.history).length } Scans</>
                )}
            </h1>

            { !props.isLoading &&
             (!props.cust.history || Object.keys(props.cust.history).length === 0) && (
                <p>Es liegen keine historischen Daten f&uuml;r den gew&auml;hlten Kunden vor!</p>
             )}

            { !props.isLoading && props.cust.history && Object.keys(props.cust.history).length > 0 && (
                <>
                    { sortedHistoryKeys.map(scanId => {
                        return (
                            <HistoryListItem
                                //  a unique key for this element
                                key={"history-list-item-"+scanId}

                                //  some states
                                navigate={props.navigate}

                                //  data
                                scanId={scanId}
                                cust={props.cust}
                                scanData={props.cust.history[scanId]}
                            />
                        );
                    })}
                </>
            )}
        </>
    );
}

export default HistoryList;
