import React, {useEffect, useState} from 'react';
import { FiPlusCircle, FiMinusCircle, FiXCircle } from 'react-icons/fi';

//  import services
import { overlay_initialize } from '../../../services/overlay-service';
import { scan_getCssClass } from '../../../services/scan-service';

//  ---
//  Page/Component Ean
//  ---
const Ean = (props) => {

    //  ---
    //  initialize this ean object
    //  ---
    const [ ean, setEan ] = useState(props.ean);

    //  ---
    //  trigger parent function to increase scan item quantity
    //  ---
    function increase() {
        // update current quantity of ean
        let newQuantity = props.increaseScanQuantity(ean.ean ? ean.ean : '', 'i');
        setEan({...ean,
            currentQuantity: newQuantity,
            stockCssClassName: scan_getCssClass(props.cust, ean, newQuantity)
        });
    }

    //  ---
    //  trigger parent function to decrease scan item quantity
    //  ---
    function decrease() {
        // update eans current quantity
        let newQuantity = props.decreaseScanQuantity(ean.ean ? ean.ean : '');
        setEan({...ean,
            currentQuantity: newQuantity,
            stockCssClassName: scan_getCssClass(props.cust, ean, newQuantity)
        });
        // 'remove' if empty
        if (newQuantity === 0) {
            remove();
        }
    }

    //  ---
    //  trigger parent function to remove scan item
    //  ---
    function remove() {
        props.setOverlaySettings(overlay_initialize);
        setEan({...ean,
            currentQuantity: 0,
            stockCssClassName: scan_getCssClass(props.cust, ean, 0)
        });
        props.removeEanFromScan(ean.ean);
    }
    const askForDeletion = () => {
        props.setOverlaySettings({
            'visible': true,
            'type': 'confirmation-window',
            'headline': 'Sicherheitsabfrage',
            'messages': [
                (ean.isDefault === true
                    ? 'Wollen Sie die bisher erfasste Menge für den EAN '+ean.ean+' wirklich auf 0 stellen?'
                    : 'Wollen Sie den EAN '+ean.ean+' wirklich löschen?'
                )
            ],
            'actions': {
                'submit': {
                    'title': (
                        ean.isDefault === true
                            ? 'auf 0 stellen'
                            : 'löschen'
                    ),
                    'callback': () => { remove(); }
                },
                'abort': {
                    'title': 'nein',
                    'callback': () => {
                        props.setOverlaySettings(overlay_initialize)
                    }
                }
            }
        });
    }

    //  ---
    //  trigger ean change after scan input change
    //  ---
    useEffect(() => {
        setEan({...ean, currentQuantity: props.eanQuantity, stockCssClassName: props.currentEanCssClass });
    // eslint-disable-next-line
    }, [ props.eanQuantity, props.currentEanCssClass ]);

    //  ---
    //  return
    //  ---
    return (
        <div className={'card dotted '+(props.useStatusStyle && ean.stockCssClassName ? ean.stockCssClassName : 'plain')}>
            <table>
                <tbody>
                    <tr>
                        <td style={{'width':'250px','textAlign':'center'}}>
                            <h2>{ ean.ean }</h2>
                        </td>
                        <td style={{'width':'25px'}} rowSpan={2}>&nbsp;</td>
                        <td>
                            <h2>{ ean.articleTitle }</h2>
                        </td>
                        <td style={{'width':'60px', 'textAlign':'center', 'verticalAlign':'top'}} rowSpan={2} className={'scanItemChangeQuantityButtonBar'}>
                            { props.printActionButtons && (
                                <>
                                    { props.increaseScanQuantity && <FiPlusCircle className={'increase-quantity'} onClick={increase} /> }
                                    { (props.decreaseScanQuantity && ean.currentQuantity > 0) && <FiMinusCircle className={'decrease-quantity'} onClick={decrease} /> }
                                    { (props.removeEanFromScan && ean.currentQuantity > 0) && <FiXCircle className={'remove-quantity'} onClick={askForDeletion} /> }
                                </>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {ean.articleNumber && (
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Artikel-Nr:</td>
                                        <td>{ ean.articleNumber }</td>
                                    </tr>
                                    <tr>
                                        <td>Gr&ouml;&szlig;e:</td>
                                        <td>{ ean.size }</td>
                                    </tr>
                                </tbody>
                            </table>
                            )}
                        </td>
                        <td>
                            <table style={{'width':'auto'}}>
                                <tbody>
                                {(props.printScannedValue === true) && (
                                    <tr>
                                        <td>Erfasste Menge:</td>
                                        <td>{ ean.currentQuantity }</td>
                                    </tr>
                                )}
                                {(props.printDefaults && ean.isDefault === true) && (
                                    <>
                                        <tr>
                                            <td>Sollbestand:</td>
                                            <td>{ ean.quantity }</td>
                                        </tr>
                                    </>
                                )}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default Ean;
