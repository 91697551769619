import React from 'react';
import { FiSave, FiEdit, FiXCircle } from 'react-icons/fi';

//  import components
import ScanView from './view';
import Statistics from '../../../components/statistics';

//  import services
import { overlay_initialize } from '../../../services/overlay-service';
import { storage_load, storage_write } from '../../../services/storage-service';
import { date_timestamp } from '../../../services/date-service';
import { scan_prepareScanItems } from '../../../services/scan-service';

//  ---
//  Page/Component ScanPreview
//  ---
const ScanPreview = (props) => {
    //  ---
    //  prepare current scan item list
    //  ---
    let scanItemList = scan_prepareScanItems(props.cust, props.page, props.fullDefaultValuesList, false);

    //  ---
    //  remove from current scans
    //  ---
    function remove() {
        //  reset and hide overlay
        props.setOverlaySettings(overlay_initialize);
        let runningScans = storage_load('scans');
        if (runningScans[props.cust.key]) {
            delete runningScans[props.cust.key];
        }
        runningScans={...runningScans};
        storage_write('scans', runningScans);
        props.setRunningScans(storage_load('scans'));
    }

    //  ---
    //  takeover scan to transfer list
    //  ---
    const addToTransferList = () => {
        //  prepare an index for this scan
        let scanIndex = date_timestamp();

        //  add to transfer list
        let transferList = storage_load('transferList');
        if (!transferList[props.cust.key]) {
            transferList[props.cust.key] = {};
        }
        transferList[props.cust.key][scanIndex] = {...props.cust.scan};
        storage_write('transferList', transferList);
        props.setTransferList(storage_load('transferList'));
        //  update customer
        props.setCust({...props.cust,
            transfer: transferList[props.cust.key],
            scan: {}
        });
        //  remove from running scan
        remove();
        //  navigate to transfer list > view
        props.navigate('/transfer/view', {
            state: {
                transferIdent: scanIndex
            }
        })
    }

    //  ---
    //  discard this scan
    //  ---
    function discard() {
        remove();
        //  update customer
        props.setCust({...props.cust, scan: {}});
        // navigate to customer list
        props.navigate('/customer/list');
    }
    const askForDiscard = () => {
        props.setOverlaySettings({
            'visible': true,
            'type': 'confirmation-window',
            'headline': 'Sicherheitsabfrage',
            'messages': [
                'Wollen Sie diesen Scan wirklich komplett verwerfen?'
            ],
            'actions': {
                'submit': {
                    'title': 'verwerfen',
                    'callback': () => { discard(); }
                },
                'abort': {
                    'title': 'nein',
                    'callback': () => {
                        props.setOverlaySettings(overlay_initialize)
                    }
                }
            }
        });
    }

    //  ---
    //  return
    //  ---
    return (
        <>
            <h1>{"Vorschau"}</h1>
            <ScanView
                // data
                scanItemList={scanItemList}
                cust={props.cust}
                // settings
                useStatusStyle={true}
                printScannedValue={true}
                printDefaults={true}
                printActionButtons={false}
            />
            <div className={'card dotted plain'}>
                <h2>Statistik</h2>
                <Statistics
                    enrichedView={true}
                    defaults={props.cust.defaults}
                    items={props.cust.scan}
                />
            </div>
            <div className={"buttonBar"}>
                <button
                    className={"btn btn-primary bold"}
                    onClick={addToTransferList}
                >
                    <FiSave /> speichern
                </button>
                <button
                    className={"btn btn-secondary bold"}
                    onClick={() => {
                        props.navigate('/scan/form');
                    }}
                >
                    <FiEdit /> bearbeiten
                </button>
                <button
                    className={"btn btn-warning bold"}
                    onClick={askForDiscard}
                >
                    <FiXCircle /> verwerfen
                </button>
            </div>
        </>
    );
}

export default ScanPreview;
