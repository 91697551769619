import React from 'react';
import { FiEye, FiCornerDownRight } from 'react-icons/fi';

//  import services
import { date_getDateTimeString } from '../../../services/date-service';
import { storage_write } from '../../../services/storage-service';

//  ---
//  Page/Component TransferListCardItem
//  ---
const TransferListCardItem = (props) => {
    //  ---
    //  open view
    //  ---
    const openTransferItemView = () => {
        // initialize cust var
        props.setCust(props.cust);

        // remember customer key of used customer in local storage
        storage_write('customerKey', props.cust.key);

        // navigate to view
        props.navigate('/transfer/view', {
            state: {
                transferIdent: props.transferIdent
            }
        });
    }

    //  ---
    //  calculate simple info about scan
    //  ---
    let unitCount = 0;
    let eanCount = 0;
    let eanObjectKeys = Object.keys(props.data);
    for (let x=0; x<eanObjectKeys.length; x++) {
        unitCount = unitCount + props.data[eanObjectKeys[x]];
        if (props.data[eanObjectKeys[x]] > 0) {
            eanCount = eanCount + 1;
        }
    }

    //  ---
    //  return
    //  ---
    return (
        <div
            key={"transfer-list-item-"+props.cust.key+'-'+props.transferIdent}
            className={"card dotted plain"}
            style={{'display':'block'}}
        >
            <button
                style={{ 'float':'right', 'width':'100px' }}
                className={'btn btn-secondary'}
                onClick={openTransferItemView}
            >
                <FiEye /> anzeigen
            </button>

            <h2>{ date_getDateTimeString(props.transferIdent, true) }</h2>
            <div><FiCornerDownRight style={{ 'marginTop':'-5px' }} /> { eanCount } EANs</div>
            <div><FiCornerDownRight style={{ 'marginTop':'-5px' }} /> { unitCount } Einheiten</div>

        </div>
    );
}

export default TransferListCardItem;
