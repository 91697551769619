/* --- --- --- --- ---
    search service
   --- --- --- --- ---  */

//  import used services
import { storage_load, storage_write } from './storage-service';

//  ---
//  load current search results
//  ---
export function search_loadResults() {
    let data = storage_load('searchResults')
    return data || {};
}

//  ---
//  write current search results to local storage
//  ---
export function search_writeResults(data) {
    storage_write('searchResults', data)
}

//  ---
//  'clear' current search results
//  ---
export function search_clearResults() {
    localStorage.removeItem('searchResults');
}
