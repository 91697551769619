import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

//  import styles
import '../../styles/pages/public.scss';

//  import components
import Spinner from '../../components/spinner';

//  import services
import { rest_processLogin } from '../../services/rest-service';

//  ---
//  Page/Component Login
//  ---
const Login = () => {
    const navigate = useNavigate();

    //  define some 'global' vars
    const [ isLoading, setIsLoading ] = useState(false);

    // ---
    // handle input form field changes
    // ---
    const [inputText, setInputText] = useState({
        username: "",
        password: "",
    });
    const onFormInputFieldChange = e => {
        setInputText({
            ...inputText,
            [e.target.name]: e.target.value,
        })
    }

    // ---
    // handle login & form errors
    // ---
    const [ infoBox, setInfoBox ] = useState({
        statusCode: '',
        infoText: '',
        visibility: false
    });
    const resetLoginFormError = () => {
        setInfoBox({
            statusCode: '',
            infoText: '',
            visibility: false
        });
    }
    const handleLoginFormError = error => {
        // set custom info texts based on statusCode
        const newInfoText = (error) => {
            switch (error.status.toString()) {
                case '403':
                    return 'Zugriff verweigert';
                default:
                    return error.statusText.toString()
            }
        }
        // update info box object
        setInfoBox({
            statusCode: error.status.toString(),
            infoText: newInfoText(error),
            visibility: true
        });
    }

    //  ---
    //  send form
    //  ---
    const submitLoginRequest = e => {
        e.preventDefault();
        resetLoginFormError();
        setIsLoading(true);
        if ('' !== inputText.username.trim() &&
            '' !== inputText.password.trim()
        ) {
            // run login against backend
            rest_processLogin(
                inputText.username.toString().trim(),
                inputText.password.toString().trim(),
                function(response) {
                    if (true !== response) {
                        handleLoginFormError(response);
                        setIsLoading(false);
                    } else {
                        navigate('/');
                    }
            });
        }
    }

    //  ---
    //  return
    //  ---
    return (
        <div id="loginPage">
            <div>
                <p><img src="./images/medi-logo.192.png" alt={process.env.REACT_APP_NAME} /></p>
            </div>
            <div className={'loginProcessInfoBox' + (infoBox.visibility ? ' active' : '')}>
                <h2>{infoBox.statusCode}</h2>
                <p>{infoBox.infoText}</p>
            </div>
            <Spinner isLoading={isLoading} />
            <div>
                <form onSubmit={submitLoginRequest}>
                    <p>
                        <input
                            type="text"
                            name="username"
                            className="input-text"
                            value={inputText.username}
                            placeholder="Login"
                            onChange={onFormInputFieldChange}
                        />
                    </p>
                    <p>
                        <input
                            type="password"
                            name="password"
                            className="input-text"
                            value={inputText.password}
                            placeholder="Passwort"
                            onChange={onFormInputFieldChange}
                        />
                    </p>
                    <p>
                        <input
                            type="submit"
                            className="btn btn-primary bold"
                            value="anmelden"
                            onClick={submitLoginRequest}
                        />
                    </p>
                    <p>
                        <a href={'/password/request-reset'}>Passwort vergessen?</a>
                    </p>
                </form>
            </div>
        </div>
    );
}

export default Login;
