import React from 'react';
import { BiBarcode, BiTransferAlt } from 'react-icons/bi';
import { BsClockHistory } from 'react-icons/bs';
import { FiRefreshCw, FiDownloadCloud, FiInfo, FiSend } from 'react-icons/fi';
import Dropdown from 'react-bootstrap/Dropdown';

//  import styles
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../styles/pages/customer.scss';

//  import page components
import CustomerAddress from './address';

//  import components
import ListItem from '../../../components/list-item';

//  import services
import { customer_init } from '../../../services/customer-service';
import { storage_write } from '../../../services/storage-service';

//  ---
//  Page/Component CustomerListItem
//  ---
const CustomerListItem = (props) => {
    //  ---
    //  init another user to switch
    //  ---
    const initAnotherCustomerBeforeSwitch = (customerKey) => {
        // initialize cust var
        props.setCust(customer_init(customerKey, props.customerList, props.historyList, props.fullDefaultValuesList, props.runningScans, props.transferList, '<CustomerListItem switchSelectedCustomer />'));
        // remember customer key of used customer in local storage
        storage_write('customerKey', props.cust.key);
    }

    //  ---
    //  switch selected customer to this one
    //  ---
    const switchSelectedCustomer = () => {
        // initialize the customer to use
        initAnotherCustomerBeforeSwitch(props.cust.key);
        props.navigate('/scan/form');
    }

    //  ---
    //  calculate some vars
    //  ---
    let amountOfEansInScan = 0;
    let amountOfItemsInScan = 0;
    if (props.cust.scan) {
        let runningScanKeys = Object.keys(props.cust.scan);
        amountOfEansInScan = runningScanKeys.length;
        for (let x=0; x<runningScanKeys.length; x++) {
            amountOfItemsInScan = amountOfItemsInScan + props.cust.scan[runningScanKeys[x]];
        }
    }

    //  ---
    //  action dropdown handling
    //  ---
    function handleDropdownChoice(choice) {
        switch (choice) {
            // open transfer list
            case 'transfer:list':
                initAnotherCustomerBeforeSwitch(props.cust.key);
                props.navigate('/transfer/list');
                return true;
            // load customers defaults
            case 'defaults:load':
                //  trigger (re-)load defaults for this customer
                props.loadCustomerDefaults(props.cust.key);
                return true;
            // send customers defaults
            case 'defaults:send':
                initAnotherCustomerBeforeSwitch(props.cust.key);
                props.navigate('/customer/send-defaults');
                return true;
            // load customers history
            case 'history:load':
                //  trigger (re-)load history of this customer
                props.loadCustomersHistory(props.cust.key);
                return true;
            // open history list of this customer
            case 'history:list':
                initAnotherCustomerBeforeSwitch(props.cust.key);
                props.navigate('/history/list');
                return true;
            // open history view of latest transferred scan
            case 'history:latest':
                initAnotherCustomerBeforeSwitch(props.cust.key);
                props.navigate('/history/latest');
                return true;
            // default call not defined
            default:
                console.log(choice+' is not defined');
                return false;
        }
    }
    //  ---
    //  return
    //  ---
    return (
        <div className={"card dotted "+(props.cust.data && props.cust.data.css ? props.cust.data.css : '')}>
            <table>
                <tbody>
                <tr>
                    <td style={{'width':'40%'}}>
                        <CustomerAddress
                            cust={props.cust}
                            showAddress={true}
                            showCustomerNumber={true}
                            showCustomerEMail={true}
                            additionalStyle={'customerListItem'}
                        />
                    </td>
                    <td style={{'width':'40%'}}>
                        { amountOfEansInScan > 0
                            ? (
                                <>
                                    <ListItem itemType={'info'} text={'Laufender Scan'} />
                                    <ListItem itemType={'sub'} text={amountOfEansInScan + ' unterschiedliche EANs'} additionalStyle={{'marginLeft':'30px'}} />
                                    <ListItem itemType={'sub'} text={amountOfItemsInScan + ' Einheit'+(amountOfItemsInScan > 1 ||amountOfItemsInScan === 0 ? 'en' : '') }  additionalStyle={{'marginLeft':'30px'}} />
                                </>
                            )
                            : ''
                        }
                        { props.fullDefaultValuesList[props.cust.key] !== undefined && Object.keys(props.fullDefaultValuesList[props.cust.key]).length > 1 
                            ? <ListItem itemType={'info'} text='Sollwerte vorhanden' /> 
                        : props.fullDefaultValuesList[props.cust.key] !== undefined && Object.keys(props.fullDefaultValuesList[props.cust.key]).length >= 0 
                            ? <div style={{color: "red"}}> <ListItem itemType={'info'} text='Keine Sollwerte verfügbar'/> </div>
                        : '' }
                        { Object.keys(props.cust.history).length >= 1 
                            ? <ListItem itemType={'info'} text='Historie vorhanden' /> 
                        : props.cust.history.length === 0
                            ? <div style={{color: "red"}}> <ListItem itemType={'info'} text='Keine Historie verfügbar'/> </div>
                        : '' }
                        { props.cust.transfer && Object.keys(props.cust.transfer).length > 0 ? <ListItem itemType={'info'} text={Object.keys(props.cust.transfer).length+' Scan'+(Object.keys(props.cust.transfer).length > 1 ? 's' : '')+' zur Übertragung'} /> : ''}
                    </td>
                    <td style={{'width':'20%'}}>
                        <div>
                            <button
                               className="btn btn-secondary customerListActionButton"
                                onClick={switchSelectedCustomer}
                            >
                                <BiBarcode /><br />
                                scannen
                            </button>
                        </div>
                        <div style={{'marginTop':'10px'}}>
                            <Dropdown>
                                <Dropdown.Toggle variant="basic" id="dropdown-basic">
                                    {/*bitte wählen*/}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    { props.cust.transfer && Object.keys(props.cust.transfer).length > 0
                                        ? (
                                            <Dropdown.Item onClick={() => { handleDropdownChoice('transfer:list'); }}>
                                                <BiTransferAlt /> Transferliste &ouml;ffnen
                                            </Dropdown.Item>
                                        ) : '' }
                                    { props.isOnline && (!props.cust.defaults || Object.keys(props.cust.defaults).length === 0)
                                        ? (
                                            <Dropdown.Item onClick={() => { handleDropdownChoice('defaults:load'); }}>
                                                <FiDownloadCloud /> Sollwerte laden
                                            </Dropdown.Item>
                                        ) : '' }
                                    { props.isOnline && Object.keys(props.cust.defaults).length > 0
                                        ? (
                                            <Dropdown.Item onClick={() => { handleDropdownChoice('defaults:load'); }}>
                                                <FiRefreshCw /> Sollwerte neu laden
                                            </Dropdown.Item>
                                        ) : '' }
                                    { props.isOnline && Object.keys(props.cust.defaults).length > 0
                                        ? (
                                            <Dropdown.Item onClick={() => { handleDropdownChoice('defaults:send'); }}>
                                                <FiSend /> Sollwerte an Kunde senden
                                            </Dropdown.Item>
                                        ) : '' }
                                    { props.isOnline && (!props.cust.history || Object.keys(props.cust.history).length === 0)
                                        ? (
                                            <Dropdown.Item onClick={() => { handleDropdownChoice('history:load'); }}>
                                                <FiDownloadCloud /> Historie laden
                                            </Dropdown.Item>
                                        ) : '' }
                                    { props.isOnline && Object.keys(props.cust.history).length > 0
                                        ? (
                                            <Dropdown.Item onClick={() => { handleDropdownChoice('history:load'); }}>
                                                <FiRefreshCw /> Historie neu laden
                                            </Dropdown.Item>
                                        ) : '' }
                                    { Object.keys(props.cust.history).length > 0
                                        ? (
                                            <>
                                                <Dropdown.Item onClick={() => { handleDropdownChoice('history:list'); }}>
                                                    <BsClockHistory /> Historie &ouml;ffnen
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => { handleDropdownChoice('history:latest'); }}>
                                                    <FiInfo /> Letzte &Uuml;bertragung zeigen
                                                </Dropdown.Item>
                                            </>
                                        ) : '' }
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}

export default CustomerListItem;
