import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { BsBoxArrowUp } from 'react-icons/bs';
import { BiXCircle } from 'react-icons/bi';
import { IoReload } from 'react-icons/io5';

//  ---
//  Page/Component Faq
//  ---
const Faq  = (props) => {

    let faq = {
        0: {
            'title': 'Ein Update wurde angekündigt. Was muss ich tun?',
            'message': [
                'Updates werden immer per E-Mail angekündigt.',
                'Sie können ab dem angegebenen Datum das Update einspielen, indem Sie folgende Schritte nacheinander ausführen:',
                '1. Übertragen Sie noch zur Übertragung anstehende Scans an den Server.',
                '2. Beenden Sie die ConsiApp komplett!',
                '3. Öffnen Sie den Safari-Browser auf Ihrem iPad und darin die Seite '+process.env.REACT_APP_ERP_API_URL,
                (<>4. Laden Sie über den Reload-Button <IoReload /> neben der Adresszeile im Browser die Seite 2-3 mal kurz neu. Sie müssen sich hierzu nicht anmelden!</>),
                '5. Schließen Sie im Browser alle Tabs, die die ConsiApp geöffnet haben.',
                '6. Starten Sie die ConsiApp über das ConsiApp-Symbol auf dem Homebildschirm neu.',
                'Die App sollte nach dem Login rechts oben die angekündigte Versionsnummer ausgeben.',
                'Das Update wäre somit abgeschlossen.'
            ]
        },
        1: {
            'title': 'Ich möchte die App neu installieren, wie mache ich das?',
            'message': [
                'Hierzu muss die App zunächst vom Homebildschrim gelöscht werden. ',
                'Das funktioniert genauso wie Sie auch andere Apps vom iPad deinstallieren, '+
                'indem Sie mit dem Finger länger oder fester - je nach iPad und Einstellungen - auf das Symbol der App drücken, bis die App-Symbole beginnen zu "wackeln".',
                (<>Über das dann erscheinende <BiXCircle /> beim App-Symbol können Sie die App löschen.</>),
                'Ein weiterer Fingertipp auf den Homebildschirm sollte das "wackeln" wieder beenden.',
                'Danach folgen Sie diesen Schritten: ',
                '1. Öffnen Sie den Safari-Browser auf Ihrem iPad und darin die Seite '+process.env.REACT_APP_ERP_API_URL,
                ( <>2. Fügen Sie die App über das Symbol <BsBoxArrowUp /> neben der Adresszeile im Browser zum Homebildschirm hinzu.</>),
                '3. Schließen Sie im Browser alle Tabs, die die ConsiApp geöffnet haben.',
                '4. Starten Sie die ConsiApp über das ConsiApp-Symbol auf dem Homebildschirm.',
                'Die App sollte nach dem Login rechts oben die aktuellste Versionsnummer ausgeben. Die Installation wäre damit abgeschlossen.'
            ]
        },
        2: {
            'title': 'Funktioniert die App auch offline?',
            'message': [
                'Ja, die App funktioniert auch offline.',
                'Ob Sie online oder offline sind, sehen sie rechts oben über den roten oder grünen Status-Button',
                'Diverse Aktionen, z.B.: die Sollwerte laden, den Scan übertragen usw. sind nur im Online-Modus verfügbar.'
            ]
        },
        3: {
            'title': 'Es werden keine Kunden aufgelistet oder ein Kunde fehlt.',
            'message': [
                'Die App selbst weiss nichts von ihren Kunden, sondern bekommt die Liste der Daten immer nur vom medi ERP-System.',
                'Sie können die Liste aktualisieren in dem Sie den Aktualisieren-Button auf der Seite "Kundenliste" klicken.',
                'Fehlt nach der Aktualisierung noch ein Kunde, wenden Sie sich bitte an die Kollegen im Team IT MasterData.'
            ]
        },
        4: {
            'title': 'Ein Fehler ist aufgetreten. Was nun?',
            'message': [
                'Bitte wenden Sie sich in erster Linie immer zuerst an die Kollegen im 1st-Level-Support über die Hotline unter '+process.env.REACT_APP_MEDI_HOTLINE+'!',
                'Wenn Sie uns den Fehler per E-Mail mitteilen möchten können Sie dies auch gerne an it-support@medi.de tun. Nur beachten Sie bitte dabei, den aufgetretenen Fehler möglichst detailiert zu beschreiben, ebensoe den Weg, den Sie bis dahin genommen haben.',
                'Oft hilft uns auch ein Screenshot des aufgetretenen Fehlers weiter.'
            ]
        },
        5: {
            'title': 'Wie funktioniert der Online-/Offline-Check?',
            'message': [
                'Die App prüft bei jedem Seitenwechsel, sowie alle 15 Sekunden, ob eine Seite der ConsiApp via Internet erreichbar ist. ',
                'Ist diese Seite nicht erreichbar, so wird der Status "Offline" dargestellt und diverse Aktionen ausgeblendet oder deaktiviert. '
            ]
        }
    };

    const [ activeItem, setActiveItem ] = useState('');

    //  ---
    //  return
    //  ---
    return (
        <>
            <h1>FAQ - Häuftig gestellte Fragen</h1>

            { Object.keys(faq).map(key => {
                return (
                    <div
                        key={'faq-item-'+key}
                        className={"card dotted "+( key === activeItem ? 'magenta' : 'plain') }
                        style={{"paddingTop":"20px"}}
                        onClick={() => { setActiveItem(key); }}
                    >
                        <h2 className={ key === activeItem ? 'magenta' : '' }>{ faq[key].title }</h2>
                        <div className={ key === activeItem ? '' : 'hidden'}>
                            <p>{ Object.values(faq[key].message).map(message => {
                                return (
                                    <span key={uuidv4()}>
                                        { message }<br />
                                    </span>
                                );
                            }) }</p>
                        </div>
                    </div>
                );
            })}
        </>
    );
}

export default Faq;
