import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MD5 from 'md5';

//  import styles
import '../../styles/pages/public.scss';

//  import components
import Spinner from '../../components/spinner';

//  import services
import { rest_setNewPassword } from '../../services/rest-service';

//  ---
//  Page/Component Login
//  ---
const PasswordReset = () => {
    const navigate = useNavigate();

    //  define some 'global' vars
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isSent, setIsSent ] = useState(false);

    const [ searchParams ] = useSearchParams();
    let referenceKey = searchParams.get("resetKey");

    // ---
    // handle input form field changes
    // ---
    const [inputText, setInputText] = useState({
        username: "",
        newPassword: "",
        repeatPassword: "",
    });
    const onFormInputFieldChange = e => {
        setInputText({
            ...inputText,
            [e.target.name]: (e.target.name === 'username')
                ? e.target.value
                : e.target.value.replace(/[^a-zA-Z\d]/ig, ''),
        })
    }

    // ---
    // handle login & form errors
    // ---
    const [ infoBox, setInfoBox ] = useState({
        statusCode: '',
        infoText: '',
        visibility: false
    });
    const resetFormError = () => {
        setInfoBox({
            statusCode: '',
            infoText: '',
            visibility: false
        });
    }
    const handleFormError = error => {
        // set custom info texts based on statusCode
        const newInfoText = (error) => {
            switch (error.status.toString()) {
                case '403':
                    return 'Zugriff verweigert';
                default:
                    return error.statusText.toString()
            }
        }
        // update info box object
        setInfoBox({
            statusCode: error.status.toString(),
            infoText: newInfoText(error),
            visibility: true
        });
    }

    //  ---
    //  send form
    //  ---
    const submitRequest = e => {
        e.preventDefault();
        resetFormError();
        if (
            '' !== inputText.username.trim() &&
            '' !== inputText.newPassword.trim() &&
            '' !== inputText.repeatPassword.trim()
        ) {
            setIsLoading(true);
            rest_setNewPassword(
                inputText.username.trim(),
                MD5(inputText.newPassword.trim()).toString(),
                referenceKey,
                function(response) {
                    if (true !== response) {
                        handleFormError(response);
                        setIsLoading(false);
                        setInputText({
                            ...inputText,
                            'username': '',
                            'newPassword': '',
                            'repeatPassword': ''
                        })
                    } else {
                        setIsLoading(false);
                        setIsSent(true);
                    }
                }
            );
        }
    }

    //  ---
    //  back to login page
    //  ---
    const backToLogin = () => {
        navigate('/login');
    }

    //  ---
    //  return
    //  ---
    return (
        <div id="setPasswordPage">
            <div>
                <p><img src="../../images/medi-logo.192.png" alt={process.env.REACT_APP_NAME} /></p>
            </div>
            <div className={'loginProcessInfoBox' + (infoBox.visibility ? ' active' : '')}>
                <h2>{infoBox.statusCode}</h2>
                <p>{infoBox.infoText}</p>
            </div>
            <Spinner isLoading={isLoading} />
            <div className={isLoading || isSent ? 'hidden' : ''}>
                <form onSubmit={submitRequest}>
                    <p>
                        Geben Sie hier <u>Ihren Login</u> ein:<br />
                    </p>
                    <p>
                        <input
                            type="text"
                            name="username"
                            className="input-text"
                            value={inputText.username}
                            placeholder="Login"
                            onChange={onFormInputFieldChange}
                        />
                    </p>
                    <p>
                        Geben Sie hier ein neues Passwort ein:<br />
                    </p>
                    <p>
                        <input
                            type="password"
                            name="newPassword"
                            className="input-text"
                            value={inputText.newPassword}
                            placeholder="Neues Passwort"
                            required={true}
                            onChange={onFormInputFieldChange}
                        />
                    </p>
                    <p>
                        <input
                            type="password"
                            name="repeatPassword"
                            className="input-text"
                            value={inputText.repeatPassword}
                            placeholder="Passwort wiederholen"
                            required={true}
                            onChange={onFormInputFieldChange}
                        />
                    </p>
                    <ul>
                        <li>Mindestens 6 Zeichen</li>
                        <li>Gro&szlig;- <u>und</u> Kleinschreibung</li>
                        <li><u>und</u> Zahlen</li>
                    </ul>
                    <p>
                        <input
                            type="submit"
                            className="btn btn-primary bold"
                            value="senden"
                            disabled={!(
                                inputText.username.trim() !== '' &&
                                inputText.newPassword.trim().length >= 6 &&
                                inputText.newPassword.match(/[a-z]/g) &&
                                inputText.newPassword.match(/[A-Z]/g) &&
                                inputText.newPassword.match(/[0-9]/g) &&
                                inputText.newPassword.trim() === inputText.repeatPassword.trim()
                            )}
                            style={{'width':'110px'}}
                            onClick={submitRequest}
                        />
                    </p>
                </form>
            </div>
            <div className={"successBox "+(!isSent ? 'hidden' : '')}>
                <p>
                    Ihre Anforderung wurde versendet.<br />
                    Das neu gesetzte Passwort ist sofort aktiv!
                </p>
                <input
                    type="button"
                    style={{'width':'130px'}}
                    className="btn btn-secondary bold"
                    value="zur Anmedlung"
                    onClick={backToLogin}
                />
            </div>
        </div>
    );
}

export default PasswordReset;
